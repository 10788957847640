import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { OrgModel, OrgNotesModel } from 'src/app/models/org.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class ResellerEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  isVam: boolean = false;
  isEditing: boolean = false;
  isLoading: boolean = false;
  parentOrgs: OrgModel[] = [];
  currentUser?: UserModel;

  isReviewer: boolean = false;
  isOps: boolean = false;

  reseller_id?: string;

  reseller: OrgModel = <OrgModel>{};
  deleteResellerModalOpen = false;

  notes?: OrgNotesModel[] = [];

  resellers: OrgModel[] = [];

  form = this.fb.group({
    name: [""],
    accountOwner: [""],
    adminContact: [""],
    supportContact: [""],
    parentOrg: this.fb.group({
      id: ['', Validators.required]
    })
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService
  ) {

    const userStr = localStorage.getItem('currentUser');

    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isVam = this.currentUser?.organization.type === 'VAM';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
      this.isOps = this.currentUser?.role === 'Ops';
    }

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.isEditing = false;
      if (params.reseller_id) {
        if (!this.isReviewer && !this.isOps) {
          this.isEditing = true;
        }
        this.reseller_id = params.reseller_id;
      }
      this.onLoad(params.reseller_id);

      if (this.isReviewer || this.isOps) {
        this.breadcrumbService.setItems([{ label: 'Resellers', routerLink:"/resellers" }, { label: "view" }]);
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([{ label: 'Resellers', routerLink:"/resellers" }, { label: "edit" }]);
      } else {
        this.breadcrumbService.setItems([{ label: 'Resellers', routerLink:"/resellers" }, { label: "add" }]);
      }
    });
  }

  async onLoad(reseller_id?: string) {
    try {
      this.isLoading = true;

      if (reseller_id) {
        this.blockUI?.start();

        if (this.isReviewer || this.isOps) {
          this.disableControlsForView();
        }

        this.reseller = await this.api.get(`/resellers/${reseller_id}`)
        if (this.reseller) {
          const notes: any = await this.api.get(`/org-notes/org/${this.reseller.id}`);
          this.notes = notes.orgNotes;
          console.log(`this.notes = ${JSON.stringify(this.notes)}`);

          this.form.patchValue(this.reseller);
          if (this.isEditing) {
            this.resellers.push(this.reseller);
          }
        }
      }

      const response = await this.api.get<{ "orgs": OrgModel[] }>('/orgs');
      const filteredOrgs = response.orgs.filter( o => o.type != 'Customer' && o.id != reseller_id);
      this.parentOrgs = filteredOrgs.sort((a, b) => a.name.localeCompare(b.name));

    } finally {
      this.isLoading = false;
      this.blockUI?.stop();
    }
  }

  async onSubmit() {
    try {
      this.blockUI?.start();
      if (this.isEditing) {
        await this.api.put(`/resellers/${this.reseller_id}`, this.form.value);
      } else {
        await this.api.post('/resellers', this.form.value);
      }
      this.router.navigate(["/resellers"]);
    } catch (error) {
      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }


  disableControlsForView() {
    this.form.get('name')?.disable();
    this.form.get('accountOwner')?.disable();
    this.form.get('adminContact')?.disable();
    this.form.get('supportContact')?.disable();
    this.form.get('parentOrg')?.disable();
  }

  async onCloseDialog() {
    this.onLoad(this.reseller_id);
  }

  async onClose() {
    this.router.navigate(["/resellers"]);
  }

  clear(table: Table) {
    table.clear();
  }

  search(table: Table, event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      table.filterGlobal(element.value, 'contains');
    }
  }

}
