import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService, Message } from 'primeng/api';
import { 
  ConfigModel,
  FirewallDnatModel,
  FirewallRulesModel,
  FirewallRule,
  FirewallRuleStatusEnum,
  DhcpReservationModel,
  MessageHubChannelModel
} from 'src/app/models/config.model';
import { ApiService } from 'src/app/services/api.service';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { Utils } from 'src/app/util/utils';
import { ConfigUtils, BASE_VALUE } from 'src/app/util/config-utils';
import { DeviceModel } from 'src/app/models/device.model';
import { ipv4Validator } from '../../../validators/ipv4Validator';
import { nameValidator } from '../../../validators/nameValidator';
import { apnRegEx, cellUserRegEx, cellPwdRegEx } from 'src/app/util/regex-utils';
import { UserModel } from 'src/app/models/user.model';
import { LazyLoadEvent } from 'primeng/api';
import * as uuid from 'uuid';
import { hostnameValidator } from '../../../validators/hostnameValidator';
import { duplicateArrayValidator } from '../../../validators/duplicateArrayValidator';
import { OrgModel } from 'src/app/models/org.model';
import { environment } from 'src/environments/environment';

const diff = require('deep-diff');

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class ConfigEditComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI | undefined;

  pleaseSelectPlaceHolder = "Please Select";

  minFirmwareVersionMsg: Message[] = [];
   wiredMessages: Message[] = [];

  currentUser?: UserModel;
  isEditing: boolean = false;
  isViewing: boolean = false;
  isAdmin: boolean = false;
  isOps: boolean = false;
  isReviewer: boolean = false;
  messageHubEnabled: boolean = false;
  isLoading = false;
  config_id?: string;
  config_groupId: string = '';
  deviceConfigs: ConfigModel[] = [];
  versions: number[] = [];
  index: any = 0;
  channelOptions: any = [];
  configUtils = ConfigUtils;
  configs: ConfigModel[] = [];
  shadows: any;

  configHistoryModalOpen = false;

  devicesToUpdate : DeviceModel[] = [];

  // Modals
  updateShadowModalOpen = false;
  deleteConfigModalOpen = false;
  addAdvancedRuleModalOpen = false;
  deleteRuleModalOpen = false;
  addStaticDhcpLeaseModalOpen = false;
  deleteStaticDhcpLeaseModalOpen = false;
  profileDescriptionsModalOpen = false;
  deleteWLHostnameModalOpen = false;
  deleteWLIpaddressModalOpen = false;
  addMessageHubChannelModalOpen = false;
  deleteMessageHubChannelModalOpen = false;

  mode5Ghz: boolean = false;

  // Text interpolation for Base Value info
  baseValue = BASE_VALUE;
  maxDailyUsageBase = '';
  dataRoutingBase = '';
  dataRoutingWiredBase = false;
  devicePasswordBase = '';
  satelliteEnabledBase = '';
  dataEnabledBase = '';
  // Serial
  serialPortBase = true;
  serialBaudRateBase = '';
  flowControlBase = '';
  // Wi-Fi
  wifiEnabledBase = '';
  ssidBase = '';
  modeBase = '';
  countryBase = '';
  channelBase = '';
  securityBase = '';
  wifiPasswordBase = '';
  // Cellular
  cellularEnabledBase = '';
  apnBase = '';
  cellularUsernameBase = '';
  cellularPasswordBase = '';
  // Network TAB
  ipAddressBase = '';
  networkMaskBase = '';
  primaryDnsBase = '';
  secondaryDnsBase = '';
  dhcpEnabledBase = '';
  dhcpStartBase = '';
  dhcpEndBase = '';
  leaseOptionBase = '';
  // Network TAB - Static DHCP Leases
  dhcpLeasesChanged: boolean = false;
  staticDhcpLeases: DhcpReservationModel[] = []; 
  staticDhcpLeasesBase: DhcpReservationModel[]= [];
  staticDhcpLeaseSelected?: DhcpReservationModel;
  staticDhcpLeasesSelected?: DhcpReservationModel[] = [];
  staticDhcpLeaseSelectedBase?: DhcpReservationModel;
  isAddingDhcp = false;
  rulesToDisplayBase: FirewallRule[] = [];
  // Firewall TAB
  firewallProfileBase = '';
  selectedWLHostName?: string;
  selectedHostNameIdx?: number;
  selectedWLIpAddress?: string;
  selectedIpAddressIdx?: number;

  // Accessories TAB
  shutdownTimeEnabledBase:boolean = false;
  batteryShutdownTimeBase = '';
  emergencyReportEnabledBase: boolean = false;
  emergencyReportIntervalBase = '';

  batteryShutdownTime = '';     // Need to keep the original value
  emergencyReportInterval = ''; // Need to keep the original value

  action = '';
  source = '';
  ipAddress = '';
  sourcePort = '';
  destinationPort = '';
  comment = '';

  FIREWALL_RULES_STATUS_ENUM = FirewallRuleStatusEnum;

  desiredFirewallRulesToDisplay: FirewallRule[] = [];
  reportedFirewallRulesToDisplay: FirewallRule[] = [];
  firewallRulesBase: FirewallRulesModel[] = [];
  deviceOnlyRulesToDisplay: any[] = [];
  pendigRulesToDisplay: any[] = [];

  ruleSelected: any = {}
  ruleSelectedBase: any = {}
  isAdding = false;

  //rulesToDisplayBase: FirewallRule[] = [];

  mergedRulesToDisplayBase: FirewallRule[] = [];

  rulesSelected: FirewallRule[] = [];
  lastTableLazyLoadEvent: LazyLoadEvent = {};

  // Remote Management Tab
  shadowRemoteManagementEnabledBase = true;
  shadowRemoteManagementIntervalBase = '';

  shadowCallHistoryEnabledBase = true;
  shadowCallHistoryIntervalBase = '';

  shadowPositionReportsEnabledBase = true;
  shadowPositionReportsIntervalBase = '';

  shadowStatusReportsEnabledBase = true;
  shadowStatusReportsIntervalBase = '';

  shadowUsageUploadEnabledBase = true;
  shadowUsageUploadIntervalBase = '';

  shadowUpdateIntervalBase = '';

  // SNMP
  snmpEnabledBase = true;

  // Videosoft
  videosoftEnabledBase = false;

  // Channel Management
  isAddingMsgChannel = false;
  msgHubChannelsChanged: boolean = false;
  // msgHubChannels: MessageHubChannelModel[] = []; 
  msgHubChannelsBase: MessageHubChannelModel[]= [];
  msgChannelSelected?: MessageHubChannelModel;
  msgChannelsSelected?: MessageHubChannelModel[] = [];
  msgChannelSelectedBase?: MessageHubChannelModel;

  smhStandardTopicId: number = Number(environment.smh.standardTopicId);

  plugins = [
    { name: "Videosoft" }
  ];

  dataRoutingOptions = [
    { name: "None", value: "none" },
    { name: "Cellular Only", value: "cell" },
    { name: "Satellite Only", value: "sat" },
    { name: "Cellular then Satellite", value: "cell-sat" },
  ];

  dataRoutingOptionsWired = [
    { name: "None", value: "none" },
    { name: "Wired Internet", value: "wan0" },
    { name: "Cellular Only", value: "cell" },
    { name: "Satellite Only", value: "sat" },
    { name: "Wired Internet then Cellular then Satellite", value: "cell-sat" },
    { name: "Wired Internet then Cellular", value: "130" },
  ];

  selectedDataRoutingOptions = this.dataRoutingOptions

  modeOptions = [
    { name: "2.4 GHz", value: false },
    { name: "5 GHz", value: true },
  ];

  // Country option only for 5G
  countryOptions = [
    { name: "USA",  value: "US"},
    { name: "United Kingdom", value: "UK"},
    { name: "France", value: "FR" },
    { name: "Germany", value: "DE" },
  ];

  channelOptions5Ghz = [
    { name: "Auto", value: 0 },
    { name: "36", value: 36 },
    { name: "40", value: 40 },
    { name: "44", value: 44 },
    { name: "48", value: 48 },
    { name: "149", value: 149 },
    { name: "153", value: 153 },
    { name: "157", value: 157 },
    { name: "161", value: 161 }
  ];

  channelOptions2_4Ghz = [
    { name: "Auto", value: 0 },
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8", value: 8 },
    { name: "9", value: 9 },
    { name: "10", value: 10 },
    { name: "11", value: 11 },
  ];

  securityOptions = [
    { name: "WPA2-PSK", value: "wpa2-psk" },
  ];

  leaseOptions = [
    { name: "5 minutes", value: "5m" },
    { name: "15 minutes", value: "15m" },
    { name: "30 minutes", value: "30m" },
    { name: "1 hour", value: "1h" },
    { name: "2 hours", value: "2h" },
    { name: "4 hours", value: "4h" },
    { name: "8 hours", value: "8h" },
    { name: "12 hours", value: "12h" },
    { name: "1 day", value: "24h" },
    { name: "2 days", value: "48h" },
    { name: "3 days", value: "72h" },
    { name: "4 days", value: "96h" },
    { name: "5 days", value: "120h" },
    { name: "7 days", value: "168h" },
    { name: "30 days", value: "720h" },
    { name: "1 year", value: "8766h" },
  ];
  networkMaskOptions = [
    { name: "255.255.0.0", value: 16 },
    { name: "255.255.128.0", value: 17 },
    { name: "255.255.192.0", value: 18 },
    { name: "255.255.224.0", value: 19 },
    { name: "255.255.240.0", value: 20 },
    { name: "255.255.248.0", value: 21 },
    { name: "255.255.252.0", value: 22 },
    { name: "255.255.254.0", value: 23 },
    { name: "255.255.255.0", value: 24 },
    { name: "255.255.255.128", value: 25 },
    { name: "255.255.255.192", value: 26 },
    { name: "255.255.255.224", value: 27 },
    { name: "255.255.255.240", value: 28 },
    { name: "255.255.255.248", value: 29 },
    { name: "255.255.255.252", value: 30 },
  ];

  serialBaudRateOptions = [
    { name: "230,400", value: 230400},
    { name: "115,200", value: 115200},
    { name: "57,600", value: 57600},
    { name: "38,400", value: 38400},
    { name: "19,200", value: 19200},
    { name: "9,600", value: 9600},
    { name: "4,800", value: 4800},
    { name: "2,400", value: 2400},
    { name: "1,200", value: 1200},
  ];

  flowControlOptions = [
    { name: "None", value: "none"},
    { name: "Software", value: "software"},
    { name: "Hardware", value: "hardware"},
  ];

  protocolOptions = [
    { name: "tcp", value: "tcp" },
    { name: "udp", value: "udp" },
    { name: "tcp,udp", value: "tcp,udp" }
  ];

  firewallProfileOptions = [
    {
      name: '0. Blocked',
      value: 0,
      description: 'Blocks all network traffic to the Internet.'
    },
    {
      name: '1. Unrestricted',
      value: 1,
      description: 'Allows all outgoing (and response) network traffic to the Internet. This is the least restrictive' +
        ' option and is not recommended as it could incur high data usage fees.'
    },
    {
      name: '2. SkyLink Email',
      value: 2,
      description: '<div class="p-field">Allows for the following Compressed (optimized for SkyLink) Email services:</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="p-m-2"></i>teleport-mail</div>' +
        '</div>'
    },
    {
      name: '3. Weather',
      value: 3,
      description: '<div class="p-field">Allows the same network traffic as SkyLink Email and adds the following weather apps:</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="p-m-2"></i>LuckGrib</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>PocketGrib</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>PredictWind</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="p-m-2"></i>SEAMAN</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>Squid</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>Theyr</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="p-m-2"></i>TIMEZERO</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>Windy.app</div>' +
          '<div class="p-col box"><i class="p-m-2"></i>Windy.com</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="p-m-2"></i>XyGrib</div>' +
        '</div>'
    },
    {
      name: '4. Chatty',
      value: 4,
      description: '<div class="p-field">Allows network traffic as Weather and adds the following chat apps (text only):</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fas fa-comment fa-fw signal-chat p-m-2"></i>Signal</div>' +
          '<div class="p-col box"><i class="fab fa-telegram fa-fw p-m-2"></i>Telegram</div>' +
          '<div class="p-col box"><i class="fab fa-weixin fa-fw p-m-2"></i>WeChat</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-whatsapp fa-fw p-m-2"></i>WhatsApp</div>' +
          '<div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wickr</div>' +
          '<div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wire</div>' +
        '</div>'
    },
    {
      name: '5. Social',
      value: 5,
      description: '<div class="p-field">Allows the same network traffic as Chatty and adds the following email, social networks, chat, video and news apps:</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>AP News</div>' +
          '<div class="p-col box"><i class="fab fa-apple fa-fw p-m-2"></i>Apple Mail</div>' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>BBC News</div>' +
        '</div>' +
          '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>CNN News</div>' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Economist</div>' +
          '<div class="p-col box"><i class="fab fa-facebook fa-fw p-m-2" ></i>Facebook</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-flipboard fa-fw p-m-2"></i>Flipboard</div>' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Financial Times</div>' +
          '<div class="p-col box"><i class="fab fa-google fa-fw p-m-2"></i>Gmail</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>The Guardian</div>' +
          '<div class="p-col box"><i class="far fa-newspaper fa-fw p-m-2"></i>Huffington Post</div>' +
          '<div class="p-col box"><i class="fab fa-instagram fa-fw p-m-2"></i>Instagram</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-linkedin fa-fw p-m-2"></i>LinkedIn</div>' +
          '<div class="p-col box"><i class="fab fa-microsoft fa-fw p-m-2"></i>Outlook/Office 365</div>' +
          '<div class="p-col box"><i class="fas fa-comment fa-fw signal-chat p-m-2"></i>Signal</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-linkedin fa-fw p-m-2"></i>Sky News</div>' +
          '<div class="p-col box"><i class="fab fa-microsoft fa-fw p-m-2"></i>Teams</div>' +
          '<div class="p-col box"><i class="fab fa-telegram fa-fw p-m-2"></i>Telegram</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-tiktok fa-fw p-m-2"></i>TikTok</div>' +
          '<div class="p-col box"><i class="fab fa-twitter fa-fw p-m-2"></i>Twitter</div>' +
          '<div class="p-col box"><i class="fas fa-users fa-fw eschat p-m-2"></i>Viber (adds voice calls)</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fab fa-weixin fa-fw p-m-2"></i>WeChat (adds voice calls)</div>' +
          '<div class="p-col box"><i class="fab fa-whatsapp fa-fw p-m-2"></i>WhatsApp (adds voice calls)</div>' +
          '<div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wickr</div>' +
        '</div>' +
        '<div class="p-field p-grid">' +
          '<div class="p-col box"><i class="fa fa-comments fa-fw p-m-2 text-white"></i>Wire (adds voice calls)</div>' +
          '<div class="p-col box"><i class="fas fa-video fa-fw zoom p-m-2"></i>Zoom</div>' +
          '<div class="p-col box"><i class="fas fa-fw p-m-2"></i></div>' +
        '</div>'
    },
    {
      name: '6. Email',
      value: 6,
      description: '<div class="p-field">Allows the same network traffic as SkyLink Email and adds support for the common email (POP3/IMAP/SMTP) protocos and the following additional email services:</div>' +
      '<div class="p-field p-grid">' +
        '<div class="p-col "><i class="fab fa-apple fa-fw p-m-2"></i>Apple Mail</div>' +
        '<div class="p-col "><i class="fab fa-google fa-fw p-m-2"></i>Gmail</div>' +
        '<div class="p-col "><i class="fab fa-microsoft fa-fw p-m-2"></i>Outlook/Office 365</div>' +
      '</div>'
    },
    {
      name: '7. Whitelist',
      value: 7,
      description: '<div class="p-field" style="background: pink">' +
        '<div class="p-field">Allows network traffic for the items defined in the <b>Whitelist</b>. All other traffic is blocked.</div>' +
      '</div>'
    }
  ];

  emergencyReportIntervalOptions = [
    { name: "15 seconds", value: "15s" },
    { name: "30 seconds", value: "30s" },
    { name: "45 seconds", value: "45s" },
    { name: "1 minute", value: "1m" },
    { name: "2 minutes", value: "2m" },
    { name: "5 minutes", value: "5m" },
    { name: "10 minutes", value: "10m" },
    { name: "Custom", value: "custom" },
  ];

  intervalOptions = [
    { name: "1 minute", value: "1m" },
    { name: "5 minutes", value: "5m" },
    { name: "10 minutes", value: "10m" },
    { name: "20 minutes", value: "20m" },
    { name: "30 minutes", value: "30m" },
    { name: "45 minutes", value: "45m" },
    { name: "1 hour", value: "1h" },
    { name: "2 hours", value: "2h" },
    { name: "4 hours", value: "4h" },
    { name: "6 hours", value: "6h" },
    { name: "12 hours", value: "12h" },
    { name: "24 hours", value: "24h" },
    { name: "7 days", value: "168h" },
    { name: "Custom", value: "custom" }
  ];

  dataUsageIntervalOptions = [
    { name: "1 hour", value: "1h" },
    { name: "2 hours", value: "2h" },
    { name: "4 hours", value: "4h" },
    { name: "6 hours", value: "6h" },
    { name: "12 hours", value: "12h" },
    { name: "24 hours", value: "24h" },
    { name: "2 days", value: "48h" },
    { name: "7 days", value: "168h" },
    { name: "Custom", value: "custom" }
  ];

  configSyncIntervalOptions = [
    { name: "1 hour", value: "1h" },
    { name: "4 hours", value: "4h" },
    { name: "8 hours", value: "8h" },
    { name: "12 hours", value: "12h" },
    { name: "24 hours", value: "24h" }
  ];

  reportingCustomUnitsOptions = [
    { name: 'second(s)', value: 's'},
    { name: 'minute(s)', value: 'm'},
    { name: 'hour(s)', value: 'h'},
    { name: 'day(s)', value: 'd'},
  ];

  dataUsageCustomUnitsOptions = [
    { name: 'hour(s)', value: 'h'},
    { name: 'day(s)', value: 'd'},
  ];

  description: any = this.firewallProfileOptions[0].description;

  form = this.fb.group({
    name: [{ value: null, disabled: false },
      [Validators.required, Validators.minLength(5), Validators.maxLength(20), nameValidator()]],
    version: [{ value: null, disabled: true }],

    // General Settings TAB
    // maxDailyUsage: [{ value: "(COMING SOON)", disabled: true }],  // TODO:  Coming Soon
    dataRoutingWired: [{ value: false, disabled: false }],
    dataRouting: [{ value: "none", disabled: false }],
    serialPort: [{ value: true, disabled: false }],
    // devicePassword: [{ value: "(COMING SOON)", disabled: true }],  // TODO: Coming Soon
    satelliteEnabled: [{ value: true, disabled: false }],
    dataEnabled: [{ value: true, disabled: false }],
    serialBaudRate: [{ value: 230400, disabled: false}],
    flowControl: [{ value: "none", disabled: false}],
    // Wi-Fi
    wifiEnabled: [{ value: true, disabled: false }],
    ssid: [{ value: null, disabled: false }, [Validators.minLength(2), Validators.maxLength(31)]],
    mode: [{ value: false, disabled: false }],
    country: [{ value: null, disabled: false }],
    channel: [{ value: "0", disabled: false }],
    security: [{ value: "wpa2-psk", disabled: false }],
    wifiPassword: [{ value: null, disabled: false }, [Validators.minLength(8), Validators.maxLength(63)]],
    // Cellular
    cellularEnabled: [{ value: true, disabled: false }],
    apn: [{ value: null, disabled: false }, [Validators.maxLength(50), nameValidator(apnRegEx)]],
    cellularUsername: [{ value: "", disabled: false }, [Validators.maxLength(50), nameValidator(cellUserRegEx)]],
    cellularPassword: [{ value: "", disabled: false }, [Validators.maxLength(50), nameValidator(cellPwdRegEx)]],
    // Network TAB
    ipAddress: [{ value: "", disabled: false }, [Validators.required, ipv4Validator()]],
    networkMask: [{ value: null, disabled: false }],
    primaryDns: [{ value: "", disabled: false }, [ipv4Validator()]],
    secondaryDns: [{ value: "", disabled: false }, [ipv4Validator()]],
    dhcpEnabled: [{ value: true, disabled: false }],
    dhcpStart: [{ value: "", disabled: false }, [ipv4Validator()]],
    dhcpEnd: [{ value: "", disabled: false }, [ipv4Validator()]],
    leaseOption: [{ value: "24h", disabled: false }],

    // Firewall TAB
    // -- Whitelist TAB (inside Firewall)
    whitelistHostnames: this.fb.array([]),
    whitelistHostname: [{ value: "", disabled: false }, [hostnameValidator()]],
    whitelistIpAddresses: this.fb.array([]),
    whitelistIpAddress:[{ value: "", disabled: false }, [ipv4Validator()]],

    // -- Port Forwarding (inside Firewall)
    firewallProfile: [{ value: "", disabled: false }],
    advancedRules: this.fb.array([]),

    // Accessories TAB
    shutdownTimeEnabled:  [{ value: false, disabled: false }],
    shutdownDays: [{ value: "", disabled: false }],
    shutdownHrs: [{ value: "", disabled: false }],
    shutdownMins: [{ value: "", disabled: false }],
    shutdownSecs: [{ value: "", disabled: false }],
    emergencyReportEnabled:  [{ value: false, disabled: false }],
    emergencyReportInterval: [{ value: "1m", disabled: false }],
    reportingCustomInterval: [{ value: "1", disabled: false }],
    reportingCustomUnits: [{ value: "m", disabled: false }],

    // SNMP
    snmpEnabled: [{ value: true, disabled: false }],

    // Remote Management Tab
    remoteManagementEnabled: [{ value: true, disabled: false }],
    remoteManagementInterval: [{ value: "24h", disabled: false }, Validators.required],
    remoteManagementCustomUnits: [{ value: "h", disabled: false }],
    remoteManagementCustomInterval: [{ value: "", disabled: false }], 

    statusReportsEnabled: [{ value: true, disabled: false }],
    statusReportsInterval: [{ value: "24h", disabled: false }, Validators.required],
    statusReportsCustomUnits: [{ value: "h", disabled: false }],
    statusReportsCustomInterval: [{ value: "", disabled: false }], 

    callHistoryEnabled: [{ value: true, disabled: false }],
    callHistoryInterval: [{ value: "24h", disabled: false }, Validators.required],
    callHistoryCustomUnits: [{ value: "h", disabled: false }],
    callHistoryCustomInterval: [{ value: "", disabled: false }], 

    positionReportsEnabled: [{ value: true, disabled: false }],
    positionReportsInterval: [{ value: "24h", disabled: false }, Validators.required],
    positionReportsCustomUnits: [{ value: "h", disabled: false }],
    positionReportsCustomInterval: [{ value: "", disabled: false }], 

    usageUploadEnabled: [{ value: true, disabled: false }],
    usageUploadInterval: [{ value: "24h", disabled: false }, Validators.required],
    usageUploadCustomUnits: [{ value: "h", disabled: false }],
    usageUploadCustomInterval: [{ value: "", disabled: false }],

    shadowUpdateInterval: [{ value: "24h", disabled: false }, Validators.required],
    shadowUpdateCustomUnits: [{ value: "h", disabled: false }],
    shadowUpdateCustomInterval: [{ value: "", disabled: false }],

    // Channel Management TAB
    messageHubChannels: this.fb.array([]),

    // Plugins
    videosoftEnabled: [{ value: false, disabled: false }],
  });

  /**
   * Validator calling on ngInit() to make customIntervals mandatory or not based on the interval == 'custom'
   */
   setCustomIntervalValidators() {
    this.form.get('statusReportsInterval')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.form.get('statusReportsCustomInterval')?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      } else {
        this.form.get('statusReportsCustomInterval')?.setValidators(null);
      }  
    });
    this.form.get('remoteManagementInterval')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.form.get('remoteManagementCustomInterval')?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      } else {
        this.form.get('remoteManagementCustomInterval')?.setValidators(null);
      }  
    });
    this.form.get('callHistoryInterval')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.form.get('callHistoryCustomInterval')?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      } else {
        this.form.get('callHistoryCustomInterval')?.setValidators(null);
      }  
    });
    this.form.get('usageUploadInterval')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.form.get('usageUploadCustomInterval')?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      } else {
        this.form.get('usageUploadCustomInterval')?.setValidators(null);
      }  
    });
    this.form.get('positionReportsInterval')?.valueChanges.subscribe(value => {
      if (value === 'custom') {
        this.form.get('positionReportsCustomInterval')?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
      } else {
        this.form.get('positionReportsCustomInterval')?.setValidators(null);
      }  
    });
  }

  get whitelistHostnames() {
    return this.form.get("whitelistHostnames") as FormArray;
  }

  get whitelistIpAddresses() {
    return this.form.get("whitelistIpAddresses") as FormArray;
  }

  get messageHubChannels() {
    return this.form.get("messageHubChannels") as FormArray;
  }

  getAdvancedRules() {
    return this.form.controls["advancedRules"] as FormArray;
  }

  rewrite(array :any) {
    this.form.value.advancedRules = this.fb.array(array);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService,
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      this.currentUser = JSON.parse(userStr);
      this.isAdmin = this.currentUser?.role === 'Admin';
      this.isOps = this.currentUser?.role === 'Ops';
      this.isReviewer = this.currentUser?.role === 'Reviewer';
    }
  }


  ngOnInit(): void {
    console.log("this.route = ", this.route);

    this.minFirmwareVersionMsg = [
      {severity:'warn', summary:'Warning', detail:'Minimum SkyLink firmware version needed for this feature: 2.33'}
    ];

    this.loadOrganization()

    this.route.params.subscribe(params => {
      console.log("params = ", params);

      //this.platformLocation.onPopState(() => {
      //  this.back();
      //});

      if (params.index) {
        this.index = params.index;
      }

      // Need to setup channelOptions based on the default mode (2.4GHz)
      this.channelOptions = this.getChannelOptions(this.form.value.mode);

      this.isEditing = false;
      if (params.device_id) {
        this.isViewing = true;
        this.disableControls();
        this.onLoadByDevice(params.device_id);
      } else if (params.config_id) {
        if (!this.isReviewer) {
          this.isEditing = true;
        }
        this.config_id = params.config_id;
        this.onLoad(params.config_id);
      } else {
        // Add
        this.setupControls();
        this.prepopulateNetworkConfig();
      }

      if (this.isReviewer) {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'Device Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'View Device Profile' }
        ]);
      } else if (this.isViewing) {
        this.breadcrumbService.setItems([
          { label: 'Devices', routerLink: '/devices' },
          { label: 'Edit Device', routerLink: '/devices'},
          { label: 'View Device Profile' }
        ]);
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'Device Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'Edit' }
        ]);
      } else {
        this.breadcrumbService.setItems([
          { label: 'Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'Device Profiles', routerLink: ['/configs', { 'index' : 0 }] },
          { label: 'Add' }
        ]);
      }
    });
    this.setCustomIntervalValidators();

  }

  async loadOrganization() {
    // Need to retrieve organization again
    // Organization properties saved in localStorage may be obsolete
    const orgId = this.currentUser?.organization.id;
    const organization = await this.api.get<OrgModel>(`/orgs/${orgId}`)
    this.messageHubEnabled = organization.messageHubEnabled || false;
    console.log(`In AppMenuComponen.load() - messageHubEnabled = `, this.messageHubEnabled);
  }

  async onLoadByDevice(device_id: string) {
    try {
      this.blockUI?.start();

      // Get things
      const thingRsp: any = await this.api.get(`/things/${device_id}`);
      console.log("Config Edit: thingRsp = ", thingRsp);

      this.shadows = thingRsp.shadows;
      if (this.shadows) {
        const shadowsReported = await this.parseShadowsReported(this.shadows);
        if (shadowsReported) {
          this.channelOptions = this.getChannelOptions(shadowsReported.mode);
          this.form.patchValue(shadowsReported);
          if (shadowsReported.mode)
            this.mode5Ghz = true;
        }

        const desiredHostnames = this.shadows.firewall_ipsets?.state.desired?.ipsets?.[0]?.hostnames;
        const reportedHostnames = this.shadows.firewall_ipsets?.state.reported?.ipsets?.[0]?.hostnames;
        let hostData = await this.parseAndCompareWLShadows(desiredHostnames, reportedHostnames);
        if (hostData) {
          // Arrays in the form
          // FormControls need to created for patch to work
          for (const hostName of hostData) {
            this.whitelistHostnames.push(new FormControl(hostName));
          }
        }

        const desiredIps = this.shadows.firewall_ipsets?.state.desired?.ipsets?.[0]?.ips;
        const reportedIps = this.shadows.firewall_ipsets?.state.reported?.ipsets?.[0]?.ips;
        let ipsData = await this.parseAndCompareWLShadows(desiredIps, reportedIps);
        if (ipsData) {
          // Arrays in the form
          // FormControls need to created for patch to work
          for (const ips of ipsData) {
            this.whitelistIpAddresses.push(new FormControl(ips));
          }
        }

        // Display Base Value
        await this.parseShadowsDesired(this.shadows);

        await this.mergeRulesToDisplay();
        //this.rulesToDisplayBase = await this.mergeRulesToDisplay();

        // Display Static DHCP Leases
        const { dhcpData } = await this.parseAndCompareShadows(this.shadows);
        if (dhcpData) {
          this.staticDhcpLeases = dhcpData;
        }
      }

    } finally {
      this.blockUI?.stop();
    }
  }

  checkAndMarkDifference(desired: any, reported: any) {
    console.log("In checkAndMarkDifference() - desired = ", desired);
    console.log("In checkAndMarkDifference() - reported = ", reported);

    let changes = diff(desired, reported);
    console.log(`changes = ${JSON.stringify(changes)}`);
    if (changes) {
      // if they don't match, status = "modified"
      desired['status'] = 'Modified';
    } else {
      // if they match, status = "synched"
      desired['status'] = 'Synched';
    }
    console.log("Exiting checkAndMarkDifference() - desired = ", desired);
    return desired;
  }

  compareShadowData(desired: any, reported: any) {
    let diffedData: any = [];
    console.log(`In compareShadowData(): desired: `, desired);
    console.log(`In compareShadowData(): reported: `, reported);

    if (desired?.length > 0 && reported?.length > 0) {
      for (let i=0; i<desired.length; i++) {
        let found: boolean = false;
        for (let j=0; j<reported.length && !found; j++) {

          if (desired[i].name && reported[j].name &&
              desired[i].name === reported[j].name) {

            found=true;
            const desiredSipRes = this.checkAndMarkDifference(desired[i], reported[j]);
            diffedData.push(desiredSipRes);

            // Remove found from the array
            reported.splice(j, 1);
            --j;
          }
        }
        // Only found in Desired side -> mark it as 'pending'
        if (!found) {
          const desiredTmp = desired[i];
          console.log(`PENDING`);
          desiredTmp['status'] = 'Pending';
          diffedData.push(desiredTmp);
        }
      }

      // Only found is Reported side -> mark it as 'device-only'
      if (reported?.length > 0) {
        const devOnlyRes = reported.map((reported: any) => ({ ...reported, ['status']: 'Device-Only'}));
        diffedData = [...diffedData, ...devOnlyRes];
      }

    } else if (desired?.length > 0 && reported?.length <= 0) {
      console.log(`PENDING`);
      // status = "pending"
      const pendingRes = desired.map((pbxDesired: any) => ({ ...pbxDesired, ['status']: 'Pending'}));
      diffedData = [...pendingRes];
    } else if (desired?.length <= 0 && reported?.length > 0) {
      console.log(`DEVICE-ONLY`);
      // status = "device-only"
      const devOnlyRes = reported.map((reported: any) => ({ ...reported, ['status']: 'Device-Only'}));
      diffedData = [...devOnlyRes];
    }
    console.log(`diffedData = `, diffedData);
    return diffedData;
  }

  async parseAndCompareShadows(shadows: any) {
    console.log("Entering parseAndCompareShadows(): shadows = ", shadows);
    let dhcpData: any = [];
    if (shadows) {
      if (Object.keys(shadows).length === 0
            && shadows.constructor === Object) {
        console.log("In parseShadows(): No shadows");
      } else {
        // Compare SIP Shadow data
        let desiredDhcpRes: any = [];
        if (shadows.dhcp?.state.desired?.reservations) {
          desiredDhcpRes = [...shadows.dhcp?.state.desired?.reservations];
        }
        let reportedDhcpRes: any = [];
        if (shadows.dhcp?.state.reported?.reservations) {
          reportedDhcpRes = [...shadows.dhcp?.state.reported?.reservations];
        }
        dhcpData = this.compareShadowData(desiredDhcpRes, reportedDhcpRes);
      }
    }
    console.log("Exiting parseShadows(): dhcpData = ", dhcpData);

    return {dhcpData};
  }

  async parseAndCompareWLShadows(desiredData: any, reportedData: any) {
    console.log("Entering parseAndCompareWLShadows(): desiredData = ", desiredData);
    console.log("Entering parseAndCompareWLShadows(): reportedData = ", reportedData);
    let data: any = [];
    if (desiredData?.length || reportedData?.length) {
      // Compare SIP Shadow data
      let desiredRes: any = [];
      if (desiredData) {
        desiredRes = [...desiredData];
      }
      let reportedRes: any = [];
      if (reportedData) {
        reportedRes = [...reportedData];
      }

      // Convert ['a', 'b'] to [{name: 'a'}, {name: 'b'}]
      const newDesired = desiredRes.map((item: any) => {
        return { name: item }
      })
      const newReported = reportedRes.map((item: any) => {
        return { name: item }
      })

      console.log(`newDesired = `, newDesired);
      console.log(`newReported = `, newReported);

      data = this.compareShadowData(newDesired, newReported);
    }
    console.log("Exiting parseAndCompareWLShadows(): data = ", data);

    return data;
  }

  compareRulesToDisplay(one: any, other: any) {
    const res = one.protocol+":"+one.ipAddress+":"+one.destinationPort+":"+one.sourcePort ===
                other.protocol+":"+other.ipAddress+":"+other.destinationPort+":"+other.sourcePort
    return res
  }

  async mergeRulesToDisplay() {

    console.log(`edit-config - mergeRulesToDisplay()   reportedFirewallRulesToDisplay (REPORTED) ${JSON.stringify(this.reportedFirewallRulesToDisplay)}`);

    const synchedOrModfiedRules: FirewallRule[] = this.desiredFirewallRulesToDisplay.filter(desired => this.reportedFirewallRulesToDisplay.find(reported => this.compareRulesToDisplay(desired, reported)));
    const modifiedOrSynchedRules: FirewallRule[] = this.reportedFirewallRulesToDisplay.filter(reported => this.desiredFirewallRulesToDisplay.find(desired => this.compareRulesToDisplay(reported, desired)));
    const pending: FirewallRule[] = this.desiredFirewallRulesToDisplay.filter(desired => !this.reportedFirewallRulesToDisplay.find(reported => this.compareRulesToDisplay(desired, reported)));
    const deviceOnly: FirewallRule[] = this.reportedFirewallRulesToDisplay.filter(reported => !this.desiredFirewallRulesToDisplay.find(desired => this.compareRulesToDisplay(reported, desired)));

    // Now find out if therey diff different
    const differentRules = diff(synchedOrModfiedRules, modifiedOrSynchedRules);

    console.log("edit-config - differentRules() " +JSON.stringify(differentRules));

    // Assume all sync
    synchedOrModfiedRules.map(rule => rule.status = FirewallRuleStatusEnum.SYNCHED);
    pending.map(rule => rule.status = FirewallRuleStatusEnum.PENDING);
    deviceOnly.map(rule => rule.status = FirewallRuleStatusEnum.DEVICE_ONLY);

    differentRules?.map((change: any) => {
      console.log(`edit-config - TEST() ${JSON.stringify(change.path[0])}`)
      synchedOrModfiedRules[change.path[0]].status = FirewallRuleStatusEnum.MODIFIED;
    });
    this.mergedRulesToDisplayBase = [];
    this.mergedRulesToDisplayBase.push(...synchedOrModfiedRules);
    this.mergedRulesToDisplayBase.push(...pending);
    this.mergedRulesToDisplayBase.push(...deviceOnly);

    // New Test
    this.deviceOnlyRulesToDisplay.push(...deviceOnly);
    this.pendigRulesToDisplay.push(...pending);

    console.log(`mergeRulesToDisplay() SYNCHEDorMODIFIED RULES= ${JSON.stringify(synchedOrModfiedRules)}`);
    console.log(`mergeRulesToDisplay() PENDING RULES= ${JSON.stringify(pending)}`);
    console.log(`mergeRulesToDisplay() DEVICE_ONLY RULES= ${JSON.stringify(deviceOnly)}`);
    console.log(`mergeRulesToDisplay() MERGED RULES= ${JSON.stringify(this.mergedRulesToDisplayBase)}`);
    return this.mergedRulesToDisplayBase;
  }

  async onLoad(config_id: string) {
    try {
      this.blockUI?.start();
      this.isLoading = true;

      if (this.isReviewer) {
        this.setupControlsForView();
      }

      const configRsp = await this.api.get<ConfigModel>(`/configs/${config_id}`);
      console.log("Config Edit: configRsp = ", configRsp);
      this.config_groupId = configRsp.groupId;
      console.log("this.config_groupId = ", this.config_groupId);

      if (configRsp) {
        this.configs.push(configRsp);
      }

      const configData = await this.parseConfig(configRsp);

      if (configData) {
        // Default 2.4Ghz else 5Ghz
        this.channelOptions = this.getChannelOptions(configData.mode);

        // Patch
        this.form.patchValue(configData);

        // Arrays in the form
        // FormControls need to created for patch to work
        for (const hostName of configData.whitelistHostnames) {
          this.whitelistHostnames.push(new FormControl({name: hostName}, [Validators.required, duplicateArrayValidator(this.whitelistHostnames)]));
        }

        for (const ip of configData.whitelistIpAddresses) {
          this.whitelistIpAddresses.push(new FormControl({name: ip}, [Validators.required, duplicateArrayValidator(this.whitelistIpAddresses)]));
        }

        console.log(this.form.value);
        this.setupControls();
        if (configData.mode)
          this.mode5Ghz = true;
      }
    } finally {
      this.isLoading = false;
      this.blockUI?.stop();
    }
  }

  async parseConfig(configRsp: ConfigModel) {
    let configData: any;

    if (configRsp) {     
      const positionReportsIntervalInfo = this.translateInterval(configRsp.config.shadows.remote_management?.position_reports_interval);
      const remoteManagementIntervallInfo = this.translateInterval(configRsp.config.shadows.remote_management?.remote_management_interval);
      const callHistoryIntervallInfo = this.translateInterval(configRsp.config.shadows.remote_management?.call_history_interval);
      const statusReportsIntervallInfo = this.translateInterval(configRsp.config.shadows.remote_management?.status_report_interval);
      const usageUploadIntervallInfo = this.translateInterval(configRsp.config.shadows.remote_management?.usage_upload_interval);
      const shadowUpdateIntervallInfo = this.translateInterval(configRsp.config.shadows.remote_management?.shadow_interval);

      configData = {
        name: configRsp.name,
        version: configRsp.version,

        // Serial
        serialPort: configRsp.config.shadows.platform.serial_enabled,
        serialBaudRate: configRsp.config.shadows.modem_emulator?.baud,
        flowControl: configRsp.config.shadows.modem_emulator?.flow_control || "none",

        // Satellite
        satelliteEnabled: configRsp.config.shadows.platform.iridium_sfx_enabled,
        dataEnabled: configRsp.config.shadows.platform.iridium_sfx_data_enabled,

        // Wifi
        wifiEnabled: configRsp.config.shadows.hostapd.enabled,
        ssid: configRsp.config.shadows.hostapd.ssid,
        mode: configRsp.config.shadows.hostapd.band_5ghz,
        country: configRsp.config.shadows.hostapd.country,
        channel: configRsp.config.shadows.hostapd.channel,
//          security: ,
        wifiPassword: configRsp.config.shadows.hostapd.wpa_passphrase,

        // Cellular
        cellularEnabled: configRsp.config.shadows.platform.cellular_enabled,

        apn: configRsp.config.shadows.sims.sims[0].apn,
        cellularUsername: configRsp.config.shadows.sims.sims[0].username,
        cellularPassword: configRsp.config.shadows.sims.sims[0].password,


        // Connection Mananger
        dataRouting: configRsp.config.shadows.net_mgr.host_groups[0].default_route,
        dataRoutingWired: configRsp.config.shadows.ui.data_routing_wired,

        // LAN
        ipAddress: configRsp.config.shadows.dhcp.local_ipv4,
        networkMask: configRsp.config.shadows.dhcp.local_ipv4_prefix,
        primaryDns: configRsp.config.shadows.dhcp.dns_servers[0],
        secondaryDns: configRsp.config.shadows.dhcp.dns_servers[1],
        dhcpEnabled: configRsp.config.shadows.dhcp.enabled,
        dhcpStart: configRsp.config.shadows.dhcp.range_start,
        dhcpEnd: configRsp.config.shadows.dhcp.range_end,
        leaseOption: configRsp.config.shadows.dhcp.lease_time,
        staticDhcpLeases: configRsp.config.shadows.dhcp.reservations || [],

        // Firewall
        firewallProfile: (Utils.altFind(this.firewallProfileOptions, function(x : any) {
          return x.value === (configRsp.config.shadows.net_mgr.host_groups[0].default_firewall || 0);
        })),

        firewallRules : configRsp.config.shadows.firewall_dnat,
        whitelistHostnames: configRsp.config.shadows.firewall_ipsets?.ipsets?.[0]?.hostnames || [],
        whitelistIpAddresses: configRsp.config.shadows.firewall_ipsets?.ipsets?.[0]?.ips || [],

        // Accessories
        shutdownTimeEnabled:  configRsp.config.shadows.platform?.battery_shutdown_time < 0 ? false : true,
        shutdownDays: this.getTimerDays(configRsp.config.shadows.platform?.battery_shutdown_time),
        shutdownHrs: this.getTimerHours(configRsp.config.shadows.platform?.battery_shutdown_time),
        shutdownMins: this.getTimerMins(configRsp.config.shadows.platform?.battery_shutdown_time),
        shutdownSecs: this.getTimerSecs(configRsp.config.shadows.platform?.battery_shutdown_time),

        emergencyReportEnabled:  configRsp.config.shadows.platform?.sos_enabled,
        emergencyReportInterval: this.parseSosReportInterval(configRsp.config.shadows.remote_management?.sos_report_interval) || '1m',
        reportingCustomInterval: this.parseSosReportInterval(configRsp.config.shadows.remote_management?.sos_report_interval) === 'custom' ?
          parseInt(configRsp.config.shadows.remote_management?.sos_report_interval) : '1',
        reportingCustomUnits: this.parseSosReportInterval(configRsp.config.shadows.remote_management?.sos_report_interval) === 'custom' ?
          configRsp.config.shadows.remote_management?.sos_report_interval.slice(
          configRsp.config.shadows.remote_management?.sos_report_interval.length-1) : 'm',

        // SNMP
        snmpEnabled: configRsp.config.shadows.snmp?.enabled,

        // Remote Management
        remoteManagementEnabled: configRsp.config.shadows.remote_management?.remote_management_enabled,
        remoteManagementInterval: remoteManagementIntervallInfo?.value,
        remoteManagementCustomUnits: remoteManagementIntervallInfo?.customUnits ? remoteManagementIntervallInfo.customUnits : "h",
        remoteManagementCustomInterval: remoteManagementIntervallInfo?.customInterval,

        callHistoryEnabled: configRsp.config.shadows.remote_management?.call_history_enabled,
        callHistoryInterval: callHistoryIntervallInfo?.value,
        callHistoryCustomUnits: callHistoryIntervallInfo?.customUnits ? callHistoryIntervallInfo.customUnits : "h",
        callHistoryCustomInterval: callHistoryIntervallInfo?.customInterval,

        positionReportsEnabled: configRsp.config.shadows.remote_management?.position_reports_enabled,
        positionReportsInterval: positionReportsIntervalInfo?.value,
        positionReportsCustomUnits: positionReportsIntervalInfo?.customUnits ? positionReportsIntervalInfo.customUnits : "h",
        positionReportsCustomInterval: positionReportsIntervalInfo?.customInterval,

        statusReportsEnabled: configRsp.config.shadows.remote_management?.status_reports_enabled,
        statusReportsInterval: statusReportsIntervallInfo?.value,
        statusReportsCustomUnits: statusReportsIntervallInfo?.customUnits ? statusReportsIntervallInfo.customUnits : "h",
        statusReportsCustomInterval: statusReportsIntervallInfo?.customInterval,
        
        usageUploadEnabled: configRsp.config.shadows.remote_management?.usage_upload_enabled,
        usageUploadInterval: usageUploadIntervallInfo?.value,
        usageUploadCustomUnits: usageUploadIntervallInfo?.customUnits ? usageUploadIntervallInfo.customUnits : "h",
        usageUploadCustomInterval: usageUploadIntervallInfo?.customInterval,

        shadowUpdateInterval: shadowUpdateIntervallInfo?.value,

        // Plugins
        videosoftEnabled: configRsp.config.shadows.videosoft?.enabled,
      }

      this.staticDhcpLeases = configRsp.config.shadows.dhcp?.reservations || [];
      this.desiredFirewallRulesToDisplay = this.convertModeltoDisplayableRule(configData.firewallRules?.dnat[0]?.rules);

      // Update channels (configRsp.channels)
      const channels:any = configRsp.channels

      for (let i=0; i<channels.length; i++) {
        let channelData: any = {}
        channelData.id = channels[i].id
        channelData.channelId = channels[i].channelId
        channelData.channelName = channels[i].name
        channelData.ttl = channels[i].ttl
        const endpoints = channels[i].endpoints.map((ep:any) => ep.url);
        channelData.endpoints = endpoints
        console.log(`Loading channelData = `, channelData)
        const fc = new FormControl(channelData, [Validators.required])
        this.messageHubChannels.push(fc);
      }
    }

    // Firewall Profile Description
    this.description = configData.firewallProfile.description;

    // Advanced Rules
    this.form.value.advancedRules = configData.firewallRules?.dnat[0]?.rules;

    await this.mergeRulesToDisplay();
    console.log("parseConfig() Config Edit: configData = ", JSON.stringify(configData));

    // Data Routing vs Data Routing Expanded
    if (configData.dataRoutingWired) {
      this.selectedDataRoutingOptions = this.dataRoutingOptionsWired
    } else {
      this.selectedDataRoutingOptions = this.dataRoutingOptions
    }

    return configData;
  }

  translateInterval(interval: any) {
    if (!interval) {
      return;
    }

    let response = this.intervalOptions.find(x => x.value === interval);

    if (response) {
      return {value: interval, customUnits: null, customInterval: null};
    } else {
      const customUnits = interval.charAt(interval.length -1);
      const customInterval = interval.substring(0, interval.length -1);
      return {value: "custom", customUnits: customUnits, customInterval: customInterval};
    }
  }

  async parseShadowsReported(shadows: any) {

    console.log("In parseShadowsReported - shadows = ", shadows);
    let reportedData: any = {};
    if (shadows) {
      if (Object.keys(shadows).length === 0
            && shadows.constructor === Object) {
        console.log("ConfigEdit: No shadows");
      } else {

        if (shadows.device_meta?.state.reported) {
          // Meta
          reportedData.name = shadows.device_meta.state.reported.name ;
          reportedData.version = shadows.device_meta.state.reported.version;
        }
        if (!reportedData.name) {
          reportedData.name = 'Unassigned';
        }
        if (!reportedData.version) {
          reportedData.version = 'Unversioned';
        }

        // if (shadows.ui?.state.reported) {
        //   // General Settings
        //   reportedData.maxDailyUsage = shadows.ui.state.reported.max_daily_usage;
        //   reportedData.devicePassword = shadows.ui.state.reported.device_password;
        // }

        if (shadows.net_mgr?.state.reported && shadows.net_mgr.state.reported.host_groups?.length > 0) {
          // General Settings
          reportedData.dataRouting = shadows.net_mgr.state.reported.host_groups[0].default_route;
          reportedData.firewallProfile = (Utils.altFind(this.firewallProfileOptions, function(x : any) {
            return x.value === (shadows.net_mgr.state.reported.host_groups[0].default_firewall || 0);
          }))

          this.description = reportedData.firewallProfile.description;
        }

        if (shadows.platform?.state.reported) {
          // General Settings
          reportedData.serialPort = shadows.platform.state.reported.serial_enabled;

          // Satellite
          reportedData.satelliteEnabled = shadows.platform.state.reported.iridium_sfx_enabled;
          reportedData.dataEnabled = shadows.platform.state.reported.iridium_sfx_data_enabled;

          // Shutdown
          reportedData.shutdownTimeEnabled = shadows.platform.state.reported.battery_shutdown_time < 0 ? false : true;
          if (reportedData.shutdownTimeEnabled) {
            reportedData.shutdownDays = this.getTimerDays(shadows.platform.state.reported.battery_shutdown_time);
            reportedData.shutdownHrs = this.getTimerHours(shadows.platform.state.reported.battery_shutdown_time);
            reportedData.shutdownMins = this.getTimerMins(shadows.platform.state.reported.battery_shutdown_time);
            reportedData.shutdownSecs = this.getTimerSecs(shadows.platform.state.reported.battery_shutdown_time);

            // Save Battery Shutdown Time
            this.batteryShutdownTime = shadows.platform.state.reported.battery_shutdown_time;
          }

          // Emergency Reporting
          reportedData.emergencyReportEnabled = shadows.platform.state.reported.sos_enabled;
        }

        if (shadows.modem_emulator?.state.reported) {
          reportedData.serialBaudRate = shadows.modem_emulator?.state.reported.baud;
          reportedData.flowControl = shadows.modem_emulator?.state.reported.flow_control;
        }

        if (shadows.hostapd?.state.reported) {
          reportedData.wifiEnabled = shadows.hostapd.state.reported.enabled;
          reportedData.ssid = shadows.hostapd.state.reported.ssid;
          reportedData.mode = shadows.hostapd.state.reported.band_5ghz;
          reportedData.country = shadows.hostapd.state.reported.country;
          reportedData.channel = shadows.hostapd.state.reported.channel;
    //          securityBase: "",
          reportedData.wifiPassword = shadows.hostapd.state.reported.wpa_passphrase;
        }

        if (shadows.platform?.state.reported) {
          // Cellular
          reportedData.cellularEnabled = shadows.platform.state.reported.cellular_enabled;
        }

        if (shadows.sims?.state.reported && shadows.sims.state.reported.sims?.length > 0) {
          reportedData.apn = shadows.sims.state.reported.sims[0].apn;
          reportedData.cellularUsername = shadows.sims.state.reported.sims[0].username;
          reportedData.cellularPassword = shadows.sims.state.reported.sims[0].password;
        }

        if (shadows.dhcp?.state.reported) {
          // LAN
          reportedData.ipAddress = shadows.dhcp.state.reported.local_ipv4;
          reportedData.networkMask = shadows.dhcp.state.reported.local_ipv4_prefix;
          reportedData.primaryDns = shadows.dhcp.state.reported.dns_servers[0];
          reportedData.secondaryDns = shadows.dhcp.state.reported.dns_servers[1];
          reportedData.dhcpEnabled = shadows.dhcp.state.reported.enabled;
          reportedData.dhcpStart = shadows.dhcp.state.reported.range_start;
          reportedData.dhcpEnd = shadows.dhcp.state.reported.range_end;
          reportedData.leaseOption = shadows.dhcp.state.reported.lease_time;
        }

        // Firewall Advance Rules
        if (shadows.firewall_dnat?.state.reported && shadows.firewall_dnat?.state.reported.dnat?.length > 0) {
          reportedData.firewallRules = shadows.firewall_dnat?.state.reported.dnat[0].rules;
        }

        this.reportedFirewallRulesToDisplay = this.convertModeltoDisplayableRule(reportedData.firewallRules);

        // Firewall Whitelist [firewall_ipsets]
        if (shadows.firewall_ipsets?.state.reported && shadows.firewall_ipsets?.state.reported.ipsets?.length) {

          const ipset = shadows.firewall_ipsets.state.reported.ipsets[0];
          reportedData.whitelistHostnames = ipset?.hostnames || [];
          reportedData.whitelistIpAddresses = ipset?.ips || [];

          console.log(`reportedData.whitelistHostnames = ${reportedData.whitelistHostnames}`)
          console.log(`reportedData.whitelistIpAddresses = ${reportedData.whitelistIpAddresses}`)
        }

        // Remote Management
        if (shadows.remote_management?.state.reported) {

          // SOS Button/Emergency rules
          if (shadows.remote_management.state.reported.sos_report_interval) {
            this.emergencyReportInterval = shadows.remote_management.state.reported.sos_report_interval;
            const sosReportInterval = shadows.remote_management.state.reported.sos_report_interval;
            // How to do this to be able to diff with the SkyLink
            reportedData.emergencyReportInterval = this.parseSosReportInterval(sosReportInterval) || '1m';
            reportedData.reportingCustomInterval = this.parseSosReportInterval(sosReportInterval) === 'custom' ?
              parseInt(sosReportInterval) : '1';
            reportedData.reportingCustomUnits = this.parseSosReportInterval(sosReportInterval) === 'custom' ?
              sosReportInterval.slice(sosReportInterval.length-1) : 'm';
          }

          // Remote Management
          const positionReportsIntervalInfo = this.translateInterval(shadows.remote_management?.state.reported.position_reports_interval);
          const remoteManagementIntervalInfo = this.translateInterval(shadows.remote_management?.state.reported.remote_management_interval);
          const callHistoryIntervallInfo = this.translateInterval(shadows.remote_management?.state.reported.call_history_interval);
          const statusReportsIntervalInfo = this.translateInterval(shadows.remote_management?.state.reported.status_report_interval);
          const usageUploadIntervallInfo = this.translateInterval(shadows.remote_management?.state.reported.usage_upload_interval);
          const shadowUpdateIntervallInfo = this.translateInterval(shadows.remote_management?.state.reported.shadow_interval);

          // LAN
          reportedData.remoteManagementEnabled = shadows.remote_management?.state.reported.remote_management_enabled;
          reportedData.remoteManagementInterval =  remoteManagementIntervalInfo?.value;
          reportedData.remoteManagementCustomUnits = remoteManagementIntervalInfo?.customUnits;
          reportedData.remoteManagementCustomInterval = remoteManagementIntervalInfo?.customInterval;

          reportedData.callHistoryEnabled = shadows.remote_management?.state.reported.call_history_enabled;
          reportedData.callHistoryInterval =  callHistoryIntervallInfo?.value;
          reportedData.callHistoryCustomUnits = callHistoryIntervallInfo?.customUnits;
          reportedData.callHistoryCustomInterval = callHistoryIntervallInfo?.customInterval;

          reportedData.positionReportsEnabled = shadows.remote_management?.state.reported.position_reports_enabled;
          reportedData.positionReportsInterval =  positionReportsIntervalInfo?.value;
          reportedData.positionReportsCustomUnits = positionReportsIntervalInfo?.customUnits;
          reportedData.positionReportsCustomInterval = positionReportsIntervalInfo?.customInterval;

          reportedData.statusReportsEnabled = shadows.remote_management?.state.reported.status_reports_enabled;
          reportedData.statusReportsInterval =  statusReportsIntervalInfo?.value;
          reportedData.statusReportsCustomUnits = statusReportsIntervalInfo?.customUnits;
          reportedData.statusReportsCustomInterval = statusReportsIntervalInfo?.customInterval;

          reportedData.usageUploadEnabled = shadows.remote_management?.state.reported.usage_upload_enabled;
          reportedData.usageUploadInterval =  usageUploadIntervallInfo?.value;
          reportedData.usageUploadCustomUnits = usageUploadIntervallInfo?.customUnits;
          reportedData.usageUploadCustomInterval = usageUploadIntervallInfo?.customInterval;

          reportedData.shadowUpdateInterval =  shadowUpdateIntervallInfo?.value;
          reportedData.shadowUpdateIntervalCustomUnits = shadowUpdateIntervallInfo?.customUnits;
          reportedData.shadowUpdateIntervalCustomInterval = shadowUpdateIntervallInfo?.customInterval;

        }

        // SNMP
        if (shadows.snmp?.state.reported) {
          reportedData.snmpEnabled = shadows.snmp?.state.reported.enabled;
        }

        // Plugins
        if (shadows.videosoft?.state.reported) {
          reportedData.videosoftEnabled = shadows.videosoft?.state.reported.enabled;
        }
      }
    }
    console.log("Config Edit: reportedData = ", reportedData);
    return reportedData;
  }

  async parseShadowsDesired(shadows: any) {

    if (shadows) {
      if (Object.keys(shadows).length === 0
            && shadows.constructor === Object) {
        console.log("ConfigEdit: No shadows");
      } else {

        // Meta
        if (shadows.device_meta?.state.desired) {
          const name = shadows.device_meta.state.desired.name;
          const version = shadows.device_meta.state.desired.version;
          this.form.patchValue({name: name, version: version});
        }

        // UI
        if (shadows.ui?.state.desired) {
          this.dataRoutingWiredBase =  shadows.ui.state.desired.data_routing_wired
        }

        if (shadows.net_mgr?.state.desired && shadows.net_mgr.state.desired.host_groups?.length > 0) {
          // General Settings
          this.dataRoutingBase = shadows.net_mgr.state.desired.host_groups[0].default_route;

          // Firewall
          this.firewallProfileBase = shadows.net_mgr.state.desired.host_groups[0].default_firewall || 0;
        }


        // Platform
        if (shadows.platform?.state.desired) {
          this.serialPortBase = shadows.platform.state.desired.serial_enabled;
          this.satelliteEnabledBase = shadows.platform.state.desired.iridium_sfx_enabled;
          this.dataEnabledBase = shadows.platform.state.desired.iridium_sfx_data_enabled;
//          this.wifiEnabledBase = shadows.platform.state.desired.wifi_enabled;
          this.cellularEnabledBase = shadows.platform.state.desired.cellular_enabled;

          this.emergencyReportEnabledBase = shadows.platform.state.desired.sos_enabled;
          this.shutdownTimeEnabledBase = shadows.platform.state.desired.battery_shutdown_time < 0 ? false : true;
          if (this.shutdownTimeEnabledBase) {
            this.batteryShutdownTimeBase = shadows.platform.state.desired.battery_shutdown_time;
          }
        }

        if (shadows.modem_emulator?.state.desired) {
          this.serialBaudRateBase = shadows.modem_emulator?.state.desired.baud;
          this.flowControlBase = shadows.modem_emulator?.state.desired.flow_control;
        }

        // Hostapd
        if (shadows.hostapd?.state.desired) {
          // wifiEnabledBase
          this.wifiEnabledBase = shadows.hostapd.state.desired.enabled;

          // SSID
          this.ssidBase = shadows.hostapd.state.desired.ssid;

          // Mode
          this.modeBase = shadows.hostapd.state.desired.band_5ghz;

          // Country
          this.countryBase = shadows.hostapd.state.desired.country;

          // Channel
          this.channelBase = shadows.hostapd.state.desired.channel;

          // Wifi
          this.wifiPasswordBase = shadows.hostapd.state.desired.wpa_passphrase;

          // Security
          this.securityBase = this.securityOptions[0].value;
        }

        // Sims
        if (shadows.sims?.state.desired && shadows.sims.state.desired.sims?.length > 0) {
          this.apnBase = shadows.sims.state.desired.sims[0].apn ;
          this.cellularUsernameBase = shadows.sims.state.desired.sims[0].username;
          this.cellularPasswordBase = shadows.sims.state.desired.sims[0].password;
        }

        // Dhcp
        if (shadows.dhcp?.state.desired) {
          this.ipAddressBase = shadows.dhcp.state.desired.local_ipv4;
          this.networkMaskBase = shadows.dhcp.state.desired.local_ipv4_prefix;
          this.primaryDnsBase = shadows.dhcp.state.desired.dns_servers[0];
          this.secondaryDnsBase = shadows.dhcp.state.desired.dns_servers[1];
          this.dhcpEnabledBase = shadows.dhcp.state.desired.enabled;
          this.dhcpStartBase = shadows.dhcp.state.desired.range_start;
          this.dhcpEndBase = shadows.dhcp.state.desired.range_end;

          this.leaseOptionBase = shadows.dhcp.state.desired.lease_time;
        }

        // firewall advance rules
        if (shadows.firewall_dnat?.state.desired && shadows.firewall_dnat?.state.desired.dnat?.length > 0) {
          console.log (`parseShadowsDesired() ${JSON.stringify(shadows.firewall_dnat?.state.desired)}`);

          this.firewallRulesBase = shadows.firewall_dnat?.state.desired.dnat[0].rules;
          if (this.isViewing && this.firewallRulesBase) {
            this.desiredFirewallRulesToDisplay = this.convertModeltoDisplayableRule(this.firewallRulesBase);
          }
          console.log (`firewallRulesBase: parseShadowsDesired() ${JSON.stringify(this.firewallRulesBase)}`);
        }

        // remote management
        if (shadows.remote_management?.state.desired) {
          console.log (`parseShadowsDesired() ${JSON.stringify(shadows.remote_management?.state.desired)}`);

          this.emergencyReportIntervalBase = shadows.remote_management?.state.desired.sos_report_interval;

          this.shadowRemoteManagementEnabledBase = shadows.remote_management?.state.desired.remote_management_enabled;
          this.shadowRemoteManagementIntervalBase = shadows.remote_management?.state.desired.remote_management_interval;

          this.shadowCallHistoryEnabledBase = shadows.remote_management?.state.desired.call_history_enabled;
          this.shadowCallHistoryIntervalBase = shadows.remote_management?.state.desired.call_history_interval;

          this.shadowPositionReportsEnabledBase = shadows.remote_management?.state.desired.position_reports_enabled;
          this.shadowPositionReportsIntervalBase = shadows.remote_management?.state.desired.position_reports_interval;

          this.shadowStatusReportsEnabledBase = shadows.remote_management?.state.desired.status_reports_enabled;
          this.shadowStatusReportsIntervalBase = shadows.remote_management?.state.desired.status_report_interval;

          this.shadowUsageUploadEnabledBase = shadows.remote_management?.state.desired.usage_upload_enabled;
          this.shadowUsageUploadIntervalBase = shadows.remote_management?.state.desired.usage_upload_interval;

          this.shadowUpdateIntervalBase = shadows.remote_management?.state.desired.shadow_interval;
        }

        if (shadows.snmp?.state.desired) {
          this.snmpEnabledBase = shadows.snmp?.state.desired.enabled;
        }

        if (shadows.videosoft?.state.desired) {
          this.videosoftEnabledBase = shadows.videosoft?.state.desired.enabled;
        }
      }
    }
  }

  getPositionReportsInterval() {
    let positionReportsInterval: string = '';
    if (this.form.value.positionReportsEnabled) {
      if (this.form.value.positionReportsInterval === 'custom') {
        positionReportsInterval = this.form.value.positionReportsCustomInterval + this.form.value.positionReportsCustomUnits;
      } else {
        positionReportsInterval = this.form.value.positionReportsInterval;
      }
    }
    return positionReportsInterval;
  }

  getRemoteManagementInterval() {
    let remoteManagementInterval: string = '';
    if (this.form.value.remoteManagementEnabled) {
      if (this.form.value.remoteManagementInterval === 'custom') {
        remoteManagementInterval = this.form.value.remoteManagementCustomInterval + this.form.value.remoteManagementCustomUnits;
      } else {
        remoteManagementInterval = this.form.value.remoteManagementInterval;
      }
    }
    return remoteManagementInterval;
  }

  getStatusReportsInterval() {
    let statusReportsInterval: string = '';
    if (this.form.value.statusReportsEnabled) {
      if (this.form.value.statusReportsInterval === 'custom') {
        statusReportsInterval = this.form.value.statusReportsCustomInterval + this.form.value.statusReportsCustomUnits;
      } else {
        statusReportsInterval = this.form.value.statusReportsInterval;
      }
    }
    return statusReportsInterval;
  }

  getCallHistoryInterval() {
    let callHistoryInterval: string = '';
    if (this.form.value.callHistoryEnabled) {
      if (this.form.value.callHistoryInterval === 'custom') {
        callHistoryInterval = this.form.value.callHistoryCustomInterval + this.form.value.callHistoryCustomUnits;
      } else {
        callHistoryInterval = this.form.value.callHistoryInterval;
      }
    }
    return callHistoryInterval;
  }

  getUsageUploadInterval() {
    let usageUploadInterval: string = '';
    if (this.form.value.usageUploadEnabled) {
      if (this.form.value.usageUploadInterval === 'custom') {
        usageUploadInterval = this.form.value.usageUploadCustomInterval + this.form.value.usageUploadCustomUnits;
      } else {
        usageUploadInterval = this.form.value.usageUploadInterval;
      }
    }
    return usageUploadInterval;
  }

  getShadowUpdateInterval() {
    let shadowUpdateInterval: string = '';
    if (this.form.value.shadowUpdateInterval === 'custom') {
      shadowUpdateInterval = this.form.value.shadowUpdateCustomInterval + this.form.value.shadowUpdateCustomUnits;
    } else {
      shadowUpdateInterval = this.form.value.shadowUpdateInterval;
    }
    return shadowUpdateInterval;
  }

  convertModeltoDisplayableRule (model: FirewallRulesModel[]) {
    const rules: FirewallRule[] = [];

    model?.forEach (rule => {
      const parsedDest = rule.dest.split(':');
      const newRule:FirewallRule = {
        action: 'Forward',
        source: 'Internet',
        ipAddress: parsedDest[1],
        sourcePort: rule.dport,
        destinationPort: parsedDest[2],
        protocol: rule.proto,
        comment: rule.comment,
        key: parsedDest[1]+rule.dport+parsedDest[2]+rule.proto
      };
      rules.push(newRule);
    });
    return rules;
  }

  async onSubmit() {
    try {
      this.blockUI?.start();
      console.log("onSubmit() - this.config_groupId = ", this.config_groupId);

      if (this.config_groupId) {
        // Retrieve list of devices associated with this config
        this.devicesToUpdate = await this.api.get<DeviceModel[]>(`/devices/config/group/${this.config_groupId}`);
        console.log("devicesToUpdate = ", this.devicesToUpdate);

        if (this.devicesToUpdate?.length > 0) {
          // Display dialog
          console.log("updateShadowModalOpen = ", this.updateShadowModalOpen);
          this.updateShadowModalOpen = true;
        } else {
          this.submitData();
        }
      } else {
        this.submitData();
      }

    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: "Error occurred during save"
      });
    } finally {
      this.blockUI?.stop();
    }
  }

  async submitData() {
    try {
      this.blockUI?.start();
      console.log("onSubmitData() - this.form.value = ", this.form.value);
      console.log("onSubmitData() - this.messageHubChannels = ", this.messageHubChannels);

      // Set up the advancedRules Shadow data, in case the table does not get updated
      this.convertDisplayableRulesToModel(this.mergedRulesToDisplayBase);

      // // Set up Management Hub shadow data
      const { shadowChannelMgmt, dbChannelData } = ConfigUtils.createMessageHubData(this.smhStandardTopicId, this.messageHubChannels)

      const country = this.form.value.country || "";
      const config: any = {
        name: this.form.value.name,
        config: {
          shadows: {
            device_meta: {
              name: this.form.value.name,
              version: this.form.value.version,
            },
            auth: {
              users : {
                1 : {
                password : "$pbkdf2-sha512$10000$kR0.TxKJCvzBBPgLTBSjsw$uSTYl6uL82dkiyKLG4UUDIy8RqPHWIJQSTstLBAyyJ.k9N5Reg0ZvulhRWtmvPKUYxGZGoSSS8I5h1Mjo.lL0g",
                username : "admin"
                }
              }
            },
            ui: {
              data_routing_wired: this.form.value.dataRoutingWired,
            },
            dhcp: {
              dns_servers: [this.form.value.primaryDns, this.form.value.secondaryDns],
              enabled: this.form.value.dhcpEnabled,
              lease_time: this.form.value.leaseOption,
              local_domain: "lan",
              local_ipv4: this.form.value.ipAddress,
              local_ipv4_prefix: this.form.value.networkMask,
              range_end: this.form.value.dhcpEnd,
              range_start: this.form.value.dhcpStart,
              reservations: this.staticDhcpLeases
            },
            hostapd: {
              band_5ghz: this.form.value.mode,  // 5ghz true else false
              channel: this.form.value.channel,
              //country: this.form.value.country,
              enabled: this.form.value.wifiEnabled,
              ssid: this.form.value.ssid,
              wpa_passphrase: this.form.value.wifiPassword
            },
            modem_emulator: {
              // TODO: grab the baud list.
              baud: this.form.value.serialBaudRate,
            //   convert_tel_to_ip: true,
            //   destinations: [
            //     %Database.DefaultDB.ModemEmulator.Destination{
            //       description: "echo test",
            //       pv4: "127.0.0.1",
            //       port: 7777,
            //       tel: "7777"
            //     }
            //   ],
              enabled: true,
              flow_control: this.form.value.flowControl
            },
            // netInterfaces: {
            //   netInterfaces: [
            //     {
            //       ipv4: this.form.value.ipAddress + "/" + this.form.value.networkMask,
            //       name: "br0",
            //       no_update_resolv_conf: false,
            //       routes: ["224.0.0.0/4 dev br0"]
            //     },
            //     {
            //       ipv4: "dhcp",
            //       name: "wan0",
            //       no_update_resolv_conf: true,
            //       routes: nil
            //     }
            //   ]
            // },
            net_mgr: {
              host_groups: [
                {
                  default_firewall: this.form.value.firewallProfile.value,
                  default_route: this.form.value.dataRouting,
              //     devices: null,
                  id: 1,
              //     name: "All (uncategorised) devices",
              //     sub_groups: null
                }
              ],
              // route_groups: [
              //   {
              //     id: 129,
              //     name: "Cellular default, satellite backup",
              //     routes: [{"name": "ppp0"}, {"name": "ppp10"}]
              //   }
              // ]
            },
            platform: {
              cellular_enabled: this.form.value.cellularEnabled,
              iridium_sfx_data_enabled: this.form.value.dataEnabled,
              iridium_sfx_enabled: this.form.value.satelliteEnabled,
              serial_enabled: this.form.value.serialPort,
//              wifi_enabled: true,  // Per Ed's api, this is always true. Ig
              sos_enabled: this.form.value.emergencyReportEnabled,
              battery_shutdown_time: this.getShutdownTime(),
            },
            // system: {
            //   asset_tag: this.form.value.ssid
            // },
            sims: {
              sims: [
                {
                  apn: this.form.value.apn,
                  friendly_name: "SkyLink Cell",
                //  id: "8988",
                  password: this.form.value.cellularPassword,
                  username: this.form.value.cellularUsername
                }
              ]
            },
            snmp: {
              community_ro: "snmp_ro",
              community_rw: "snmp_rw",
              enabled: this.form.value.snmpEnabled
            },
            firewall_dnat: {
              dnat : [{
                name: "nat-in",
                rules:  this.form.value.advancedRules
              }]
            },
            firewall_ipsets: {
              ipsets: [{
                name: "custom_basic_whitelist",
                description: "basic mode whitelist",
                hostnames: this.form.value.whitelistHostnames.map((host: any) => {return host.name}),
                ips: this.form.value.whitelistIpAddresses.map((ip: any) => {return ip.name})
              }]
            },
            remote_management: {
              call_history_enabled: this.form.value.callHistoryEnabled,
              call_history_interval: this.getCallHistoryInterval(),
              
              position_reports_enabled: this.form.value.positionReportsEnabled,
              position_reports_interval: this.getPositionReportsInterval(),
              
              remote_management_enabled: this.form.value.remoteManagementEnabled,
              remote_management_interval: this.getRemoteManagementInterval(),
              
              status_reports_enabled: this.form.value.statusReportsEnabled,
              status_report_interval: this.getStatusReportsInterval(),

              usage_upload_enabled: this.form.value.usageUploadEnabled,
              usage_upload_interval: this.getUsageUploadInterval(),

              shadow_interval: this.getShadowUpdateInterval(),

              sos_report_interval: this.getSosReportInterval()
            },
            messaging_hub: shadowChannelMgmt,
            videosoft: {
              enabled: this.form.value.videosoftEnabled
            }
          }
        }
      }

         // Only add country info if 5G
      if (config.config.shadows.hostapd.band_5ghz) {
        config.config.shadows.hostapd.country = this.form.value.country;
      }

      let updatedConfig: ConfigModel;
      let updatedChannels: any;
      if (this.isEditing) {
        // We do a POST because it creates a new one with a new version
        config.groupId = this.config_groupId;

        console.log("EDIT - Sending this config = ", config);
        updatedConfig = await this.api.post(`/configs`, config);

        // Update channel table
        dbChannelData.map(channel => {
          channel.configId=updatedConfig.id 
        })
        let channels:any = { channels: dbChannelData }

        // Do I still have channels or were they removed?
        if (channels.channels.length) {
          console.log("EDIT - Sending this channels = ", channels);
          updatedChannels = await this.api.post(`/channels/bulk`, channels);
          console.log(`updatedChannels= ${JSON.stringify(updatedChannels)}`);
        }

      } else {
        console.log("NEW - Sending this config = ", config);
        updatedConfig = await this.api.post('/configs', config);
        console.log(`updatedConfig= ${JSON.stringify(updatedConfig)}`);

        // New channel
        dbChannelData.map(channel => {
          channel.configId=updatedConfig.id 
        })
        let channels = { channels: dbChannelData }
        console.log("NEW - Sending this channels = ", channels);
        updatedChannels = await this.api.post(`/channels/bulk`, channels);
        console.log(`updatedChannels= ${JSON.stringify(updatedChannels)}`);
      }


      // Sync the Device Config changes with ALL devices
      for (let device of this.devicesToUpdate) {

        // Update the deviceConfigId
        const deviceUpdate = {
          name: device.name,
          assetTag: device.assetTag,
          tags: device.tags,
          attributes: device.attributes,
          deviceConfigId: updatedConfig.id,
          voiceConfigId: device.voiceConfigId,
          orgId: device.orgId
        }

        // Update the device with the new Device Config
        await this.api.put(`/devices/${device.id}`, deviceUpdate);

        // Update the AWS Things Shadow
        await this.api.put(`/things/${device.id}/shadows`, updatedConfig.config.shadows);

      }

      this.router.navigate(['/configs', { 'index' : 0 }]);
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: "Error occurred during save"
      });
    } finally {
      this.blockUI?.stop();
    }
  }

  // HELPER FUNCTIONS FOR RULES
  comparePortFowardRule(one: FirewallRule, other: FirewallRule) {
    return (one.ipAddress+":"+one.destinationPort === other.ipAddress+":"+other.destinationPort)
  }

  /**
   *
   * This function is to create a new advancedRule when the ipAddress and Port are different
   * If anything else changes, then the same advancedRule is updated
   *
   * THIS IS CALLED FROM THE addRule()
   *
   * @param rules
   */
  async convertDisplayableRuleToModel(rules: FirewallRule[]) {
    rules.forEach (rule => {
      const modelRule: FirewallRulesModel = {
        comment: rule.comment,
        dest: `loc:${rule.ipAddress}:${rule.destinationPort}`,
        dport: rule.sourcePort,
        proto: rule.protocol,
        source: `net`,

      };
      let newEntry = false;

      this.form.value.advancedRules.forEach((entry: any, index: number) => {
        if (entry.dest !== `loc:${rule.ipAddress}:${rule.destinationPort}`) {
          this.form.value.advancedRules[index] = entry;
          newEntry = true;
        }
      });

      if (newEntry) {
        this.form.value.advancedRules.push(modelRule);
      }
    });
    this.form.patchValue(this.form.value.advancedRules);
    console.log(`convertDisplayableRuleToModel() advancedRules value = ${JSON.stringify(this.form.value.advancedRules)}`);
  }

  // To refresh the data in the p-table
  async loadUserData(event: LazyLoadEvent)   {
    this.lastTableLazyLoadEvent = event;
  }

  /**
   *
   * This function is called to convert the rules from the UI Component to the
   * format necessary by the Shadow.
   *
   * This is called by the submit()
   *
   * @param rules
   */
  async convertDisplayableRulesToModel (rules: FirewallRule[]) {
    const firewallRulesModel: FirewallRulesModel[] = [];
    rules.forEach (rule => {
      const modelRule: FirewallRulesModel = {
        comment: rule.comment,
        dest: `loc:${rule.ipAddress}:${rule.destinationPort}`,
        dport: rule.sourcePort,
        proto: rule.protocol,
        source: `net`,
      };
      firewallRulesModel.push(modelRule);
    });

    (this.form.controls['advancedRules']).reset();
      firewallRulesModel.forEach ( r => {
      this.form.value.advancedRules.push(r);
    })

    //this.form.value.advancedRules = firewallRulesModel;

    this.form.patchValue(this.form.value.advancedRules);
    console.log(`convertDisplayableRuleToModel advancedRules ${this.form.value.advancedRules.length}  value = ${JSON.stringify(this.form.value.advancedRules)}`);
  }

  // CallBacks when Modal is closed
  async onRulesDeleted(firewallRulesBase: FirewallRule[]) {
    console.log(`rules deleted:::  ${firewallRulesBase.length} onRulesDeonRulesDeletedetedClose ${JSON.stringify(firewallRulesBase)}]`);
    this.mergedRulesToDisplayBase = firewallRulesBase;
    this.deleteRuleModalOpen = false;
    this.convertDisplayableRulesToModel(this.mergedRulesToDisplayBase);
    this.rulesSelected = [];
    (this.form.get('advancedRules') as FormArray).markAsDirty();
  }

  async onRuleAdded(firewallRule: FirewallRule) {
    this.addAdvancedRuleModalOpen = false;
    (this.form.get('advancedRules') as FormArray).markAsDirty();
    let newEntry = true
    this.mergedRulesToDisplayBase.map((rule, index) =>{
      if (this.comparePortFowardRule(rule, firewallRule)) {
        this.mergedRulesToDisplayBase[index] = firewallRule;
        newEntry = false;
      }
    });

    if (newEntry) {
      this.mergedRulesToDisplayBase.push(firewallRule);
    }
    console.log(`onRuleAdded  mergedRulesToDisplayBase= ${JSON.stringify(this.mergedRulesToDisplayBase)}`);

   this.convertDisplayableRuleToModel(this.mergedRulesToDisplayBase);
  }

  // ON-Click Events
  async onDeleteRule(firewall_rule: FirewallRulesModel[]) {
    console.log (`onDeleteRule= ${JSON.stringify(JSON.stringify(this.rulesSelected))}`)
    this.deleteRuleModalOpen = true;
  }

  async onEditRule(firewallRule: FirewallRule) {

    console.log (`onEditRule= ${JSON.stringify(firewallRule)}`)
    console.log (`onEditRule deviceOnlyRulesToDisplay= ${JSON.stringify(this.deviceOnlyRulesToDisplay)}`);
    if (this.isViewing) {
      // Populating the modal with proper data
      if (firewallRule.status === this.FIREWALL_RULES_STATUS_ENUM.DEVICE_ONLY) {
        this.ruleSelected = this.deviceOnlyRulesToDisplay.find(rule => this.compareRulesToDisplay(rule, firewallRule))
        this.ruleSelectedBase = [];
        //this.ruleSelected = this.reportedFirewallRulesToDisplay.find(rule => this.compareRulesToDisplay(rule, firewallRule))
      } else if (firewallRule.status === this.FIREWALL_RULES_STATUS_ENUM.PENDING) {
        this.ruleSelected = [];
        this.ruleSelectedBase = this.pendigRulesToDisplay.find(rule => this.compareRulesToDisplay(rule, firewallRule));
      } else {
        const rule = this.mergedRulesToDisplayBase.find(rule => this.compareRulesToDisplay(rule, firewallRule));
        if (rule?.status === this.FIREWALL_RULES_STATUS_ENUM.SYNCHED) {
          this.ruleSelected = firewallRule;
          this.ruleSelectedBase = firewallRule;
        } else {
          this.ruleSelected = this.reportedFirewallRulesToDisplay.find(rule => this.compareRulesToDisplay(rule, firewallRule));
          this.ruleSelectedBase = firewallRule;
        }
      }
      console.log (`onEditRule ruleSelectedBase= ${JSON.stringify(this.ruleSelectedBase)}`);
      console.log (`onEditRule ruleSelected= ${JSON.stringify(this.ruleSelected)}`)

    } else {
      // Editing so use the one from mergedRule to selection
      this.ruleSelected = firewallRule;
      this.isAdding = false;
    }

    this.addAdvancedRuleModalOpen = true;
  }

  async onAddRule() {
    this.isAdding = true;
    this.addAdvancedRuleModalOpen = true;
    this.ruleSelected = []
    //this.ruleSelected = [];
    console.log (`onAddRule() ${this.isAdding}`)
  }

  // Function for Static DHCP Leases
  async onStaticDhcpLeaseDeleted(deleteStaticDhcpLeases: DhcpReservationModel[]) {
    console.log(`onStaticDhcpLeaseDeleted() deleted:::  ${JSON.stringify(deleteStaticDhcpLeases)}`);
    this.deleteStaticDhcpLeaseModalOpen = false;
    this.dhcpLeasesChanged = true;

    for (let i=0; i<deleteStaticDhcpLeases.length; i++) {
      for (let j=0; j<this.staticDhcpLeases.length; j++) {
        if (deleteStaticDhcpLeases[i].id == this.staticDhcpLeases[j].id) {
          // Remove staticDhcpLease
          this.staticDhcpLeases.splice(j, 1)
          break;
        }
      }
    }

    this.staticDhcpLeasesSelected = [];
  }

  async onStaticDhcpLeaseAdded(staticDhcpLease: DhcpReservationModel) {
    console.log(`onStaticDhcpLeaseAdded() added:::  ${JSON.stringify(staticDhcpLease)}`);
    console.log(`onStaticDhcpLeaseAdded() added:::  ${JSON.stringify(this.staticDhcpLeases)}`);

    this.addAdvancedRuleModalOpen = false;

    // Enable Save button
    this.dhcpLeasesChanged = true;

    let matched = false;

    // Figure out if this Static DHCP already exists
    // Therefore, it would be an EDIT
    for (let dhcp of this.staticDhcpLeases) {
      if (dhcp.id == staticDhcpLease.id) {
        matched = true;
        // Update object
        dhcp.hwaddr = staticDhcpLease.hwaddr;
        dhcp.ipv4 = staticDhcpLease.ipv4;
        dhcp.hostname = staticDhcpLease.hostname;
        dhcp.set_tag = staticDhcpLease.set_tag;
        dhcp.comment = staticDhcpLease.comment
        break;
      }
    }
    // If not matched, it's a brand new SIP extension
    if (!matched) {
      staticDhcpLease.id = uuid.v4();
      this.staticDhcpLeases.push(staticDhcpLease);
    }
    console.log(`Exiting onStaticDhcpLeaseAdded() this.staticDhcpLeases = ${JSON.stringify(this.staticDhcpLeases)}`);
  }

  // ON-Click Events
  async onDeleteStaticDhcpLease(staticDhcpLeases: DhcpReservationModel[]) {
    console.log (`onDeleteStaticDhcpLease= ${JSON.stringify(staticDhcpLeases)}`);
    this.deleteStaticDhcpLeaseModalOpen = true;
  }

  getStaticDhcpReported(name: string) {
    let staticDhcp = null;
    const dhcpReservations = this.shadows?.dhcp?.state.reported?.reservations;
    for (let dhcpRes of dhcpReservations) {
      if (dhcpRes.ext === name) {
        staticDhcp = dhcpRes;
        break
      }
    }
    return staticDhcp;
  }

  async onEditStaticDhcpLease(staticDhcp: any) {
    console.log (`onEditStaticDhcpLease= ${JSON.stringify(staticDhcp)}`);
    if (this.isViewing) {
      if (staticDhcp.status === 'Pending') {
        this.staticDhcpLeaseSelected = undefined;
        this.staticDhcpLeaseSelectedBase = staticDhcp;

      } else if (staticDhcp.status === 'Device-Only') {
        this.staticDhcpLeaseSelected = staticDhcp;
        this.staticDhcpLeaseSelectedBase = undefined;

      } else if (staticDhcp.status === 'Modified') {

        const name = staticDhcp.name;
        const staticDhcpReported = this.getStaticDhcpReported(name);

        // Passing reported
        this.staticDhcpLeaseSelected = staticDhcpReported;

        // Passing desired
        this.staticDhcpLeaseSelectedBase = staticDhcp;

      } else if (staticDhcp.status === 'Synched') {

        // Cheating a bit; No need to get the reported since they match
        this.staticDhcpLeaseSelected = staticDhcp;
        this.staticDhcpLeaseSelectedBase = staticDhcp;

      } else {
        // Log error
        console.log(`Error: Unknown status = [ ${staticDhcp.status}`);
      }

    } else {
      this.isAddingDhcp = false;
      this.staticDhcpLeaseSelected = staticDhcp;
    }
    this.addStaticDhcpLeaseModalOpen = true;

  }

  async onAddStaticDhcpLease() {
    console.log (`onAddStaticDhcpLease() ${this.isAddingDhcp}`);

    this.isAddingDhcp = true;
    this.addStaticDhcpLeaseModalOpen = true;
    this.staticDhcpLeaseSelected = undefined;
  }
  // End Static Dhcp Lease

  // Firewall Whitelisting - Hostname
  async onAddWhitelistHostname() {
    const hostname = this.form.get('whitelistHostname');
    console.log(`onAddWhitelistHostname() - hostname = ${hostname}`);
    if (hostname) {
      const fc = new FormControl({ name: hostname.value }, [Validators.required, duplicateArrayValidator(this.whitelistHostnames)])
      this.whitelistHostnames.push(fc);

      hostname.reset();

      // CHECK IF THIS IS THE BEST WAY!
      this.whitelistHostnames.markAsDirty();
      console.log(`onAddWhitelistHostname() - whitelistHostnames = ${this.whitelistHostnames.value}`);
      console.log(this.whitelistHostnames)
    }
  }
  
  async onDeleteWhitelistHostname(hostname: string, index: number) {
    console.log(`onDeleteWhitelistHostname()`);
    this.selectedWLHostName = hostname;
    this.selectedHostNameIdx = index;
    this.deleteWLHostnameModalOpen = true;

  }

  // Callback
  async onWhitelistHostnameDeleted(idx: number) {
    console.log(`onWhitelistHostnameDeleted()  `);

    this.deleteWLHostnameModalOpen = false;

    if (idx > -1) {
      this.whitelistHostnames.removeAt(idx);
      this.whitelistHostnames.markAsDirty();
    }
    console.log(`onWhitelistHostnameDeleted() - whitelistHostnames = [${this.whitelistHostnames.value}]`);
  }

   // Firewall Whitelisting - Hostname
  async onAddWhitelistIpAddress() {
    const ipAddress = this.form.get('whitelistIpAddress');
    console.log(`onAddWhitelistIpAddress() - ipAddress = ${ipAddress}`);
    if (ipAddress) {
      const fc = new FormControl({ name: ipAddress.value }, [Validators.required, duplicateArrayValidator(this.whitelistIpAddresses)])
      this.whitelistIpAddresses.push(fc);
      ipAddress?.reset();

      // IS THIS THE BEST WAY?
      this.whitelistIpAddresses.markAsDirty();

      console.log(`onAddWhitelistIpAddress() - whitelistIpAddresses = [${this.whitelistIpAddresses}]`);
    }
  }

  async onDeleteWhitelistIpAddress(ipAddress: string, index: number) {
    console.log(`onDeleteWhitelistIpAddress() `);
    this.selectedWLIpAddress = ipAddress;
    this.selectedIpAddressIdx = index;
    this.deleteWLIpaddressModalOpen = true;
  }

  // Callback
  async onWhitelistIpAddressDeleted(idx: number) {
    console.log(`onWhitelistIpAddressDeleted()  `);

    this.deleteWLIpaddressModalOpen = false;

    if (idx > -1) {
      this.whitelistIpAddresses.removeAt(idx);
      this.whitelistIpAddresses.markAsDirty();
    }
    console.log(`onDeleteWhitelistIpAddress() - whitelistIpAddresses = [${this.whitelistIpAddresses}]`);
  }
  // End Firewall Whitelisting

  // Function for Channel Management
  async onMessageHubChannelDeleted(deleteMsgHubChannels: any[]) {
    console.log(`onMessageHubChannelDeleted() deleteMsgHubChannels =  `, deleteMsgHubChannels);
    console.log(`onMessageHubChannelDeleted() this.messageHubChannels =  `, this.messageHubChannels);
    this.deleteMessageHubChannelModalOpen = false;
    this.msgHubChannelsChanged = true;

    // Need to loop through formarray
    for (let i=0; i<deleteMsgHubChannels.length; i++) {
      for (let j=0; j<this.messageHubChannels.controls.length; j++) {
        console.log(`this.messageHubChannels.controls[j] = `, this.messageHubChannels.controls[j]);
        console.log(`deleteMsgHubChannels[i].value.id = `, deleteMsgHubChannels[i].value.id);
        console.log(`this.messageHubChannels.controls[j].value.id = `, this.messageHubChannels.controls[j].value.id);

        if (deleteMsgHubChannels[i].value.id == this.messageHubChannels.controls[j].value.id) {
          console.log(`MATCH`);
          // Remove msgHubChannels
          this.messageHubChannels.removeAt(j);
          break;
        }
      }
    }

    this.msgChannelsSelected = [];
  }

  async onMessageHubChannelAdded(msgHubChannel: any) {
    console.log(`onMessageHubChannelAdded() added msgHubChannel = `, msgHubChannel);
    console.log(`onMessageHubChannelAdded() added messageHubChannels = `, this.messageHubChannels);

    this.addMessageHubChannelModalOpen = false;

    // Enable Save button
    this.msgHubChannelsChanged = true;

    let matched = false;

    // Figure out if this Message Hub Channel already exists
    // Therefore, it would be an EDIT
    for (let i=0; i<this.messageHubChannels.length; i++) {
      const channel = this.messageHubChannels.at(i)
      if (channel.value.channelId === msgHubChannel.channelId) {
        matched = true;
        // Update object
        channel.value.channelName = msgHubChannel.channelName
        channel.value.ttl = msgHubChannel.ttl
        channel.value.endpoints = msgHubChannel.endpoints

        this.messageHubChannels.markAsDirty();
      }
    }

    // If not matched, it's a new Message Hub Channel
    if (!matched) {
      msgHubChannel.id = uuid.v4();
      //const fc = new FormControl(msgHubChannel, [Validators.required])
      const fc = new FormControl(msgHubChannel, [Validators.required, duplicateArrayValidator(this.messageHubChannels)])
      this.messageHubChannels.push(fc);

      // IS THIS THE BEST WAY?
      this.messageHubChannels.markAsDirty();

      console.log(`onMessageHubChannelAdded() - messageHubChannels = `, this.messageHubChannels);
    }
    console.log(`Exiting onMessageHubChannelAdded() this.messageHubChannels = `, this.messageHubChannels);
  }

  // ON-Click Events
  async onDeleteMessageChannel(msgSelected: any[]) {
    console.log (`onDeleteMessageChannel() - msgSelected = `, msgSelected);
    this.deleteMessageHubChannelModalOpen = true;
    this.messageHubChannels.markAsDirty();
  }

  // getStaticDhcpReported(name: string) {
  //   let staticDhcp = null;
  //   const dhcpReservations = this.shadows?.dhcp?.state.reported?.reservations;
  //   for (let dhcpRes of dhcpReservations) {
  //     if (dhcpRes.ext === name) {
  //       staticDhcp = dhcpRes;
  //       break
  //     }
  //   }
  //   return staticDhcp;
  // }

  async onEditMessageChannel(channelSelected: any) {
    console.log (`onEditMessageChannel() - channelSelected = `, channelSelected);
    if (this.isViewing) {
      // TODO:  Unsure what to do here!

    } else {
      this.isAddingDhcp = false;
      this.msgChannelSelected = channelSelected;
    }
    this.addMessageHubChannelModalOpen = true;

  }

  async onAddMessageChannel() {
    console.log (`onAddMessageChannel() ${this.isAddingMsgChannel}`);

    this.isAddingMsgChannel = true;
    this.addMessageHubChannelModalOpen = true;
    this.msgChannelSelected = undefined;
  }
  // End -- Channel Management

  async onCancel(event: Event) {
    this.router.navigate(['/configs', { 'index' : 0 }]);
  }

  back() {
    this.router.navigate(['/configs',{ 'index' : 0 }]);
  }

  async handleModeChange(event: any) {
    console.log("handleModeChange() - e = ", event);

    if (event.value) {
      this.channelOptions = this.channelOptions5Ghz;
      this.mode5Ghz = true;
    } else {
      this.channelOptions = this.channelOptions2_4Ghz;
      this.mode5Ghz = false;
    }

  }

  async handlePositionReportsChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('positionReportsInterval')?.enable();
      this.form.get('positionReportsCustomUnits')?.enable();
      this.form.get('positionReportsCustomInterval')?.enable();
    } else {
      this.form.get('positionReportsInterval')?.disable();
      this.form.get('positionReportsCustomUnits')?.disable();
      this.form.get('positionReportsCustomInterval')?.disable();
    }
  }

  async handlePositionReportsIntervalChange(event: any) {
    console.log(`In handlePositionReportsIntervalChange() - event. value = ${event.value}`);

    if (event.value === 'custom') {
      this.form.get('positionReportsCustomUnits')?.enable();
      this.form.get('positionReportsCustomInterval')?.enable();
    } else {
      this.form.get('positionReportsCustomUnits')?.disable();
      this.form.get('positionReportsCustomInterval')?.disable();
    }
  }

  async handleRemoteManagementChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('remoteManagementInterval')?.enable();
      this.form.get('remoteManagementCustomUnits')?.enable();
      this.form.get('remoteManagementCustomInterval')?.enable();
    } else {
      this.form.get('remoteManagementInterval')?.disable();
      this.form.get('remoteManagementCustomUnits')?.disable();
      this.form.get('remoteManagementCustomInterval')?.disable();
    }
  }

  async handleRemoteManagementIntervalChange(event: any) {

    if (event.value === 'custom') {
      this.form.get('remoteManagementCustomUnits')?.enable();
      this.form.get('remoteManagementCustomInterval')?.enable();
    } else {
      this.form.get('remoteManagementCustomUnits')?.disable();
      this.form.get('remoteManagementCustomInterval')?.disable();
    }
  }

  async handleStatusReportsChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('statusReportsInterval')?.enable();
      this.form.get('statusReportCustomUnits')?.enable();
      this.form.get('statusReportCustomInterval')?.enable();
    } else {
      this.form.get('statusReportsInterval')?.disable();
      this.form.get('statusReportCustomUnits')?.disable();
      this.form.get('statusReportCustomInterval')?.disable();
    }
  }

  async handleStatusReportsIntervalChange(event: any) {

    if (event.value === 'custom') {
      this.form.get('statusReportsCustomUnits')?.enable();
      this.form.get('statusReportsCustomInterval')?.enable();
    } else {
      this.form.get('statusReportsCustomUnits')?.disable();
      this.form.get('statusReportsCustomInterval')?.disable();
    }
  }

  async handleCallHistoryChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('callHistoryInterval')?.enable();
      this.form.get('callHistoryCustomUnits')?.enable();
      this.form.get('callHistoryCustomInterval')?.enable();
    } else {
      this.form.get('callHistoryInterval')?.disable();
      this.form.get('callHistoryCustomUnits')?.disable();
      this.form.get('callHistoryCustomInterval')?.disable();
    }
  }

  async handleCallHistoryIntervalChange(event: any) {

    if (event.value === 'custom') {
      this.form.get('callHistoryCustomUnits')?.enable();
      this.form.get('callHistoryCustomInterval')?.enable();
    } else {
      this.form.get('callHistoryCustomUnits')?.disable();
      this.form.get('callHistoryCustomInterval')?.disable();
    }
  }

  async handleUsageUploadChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('usageUploadInterval')?.enable();
      this.form.get('usageUploadCustomUnits')?.enable();
      this.form.get('usageUploadCustomInterval')?.enable();
    } else {
      this.form.get('usageUploadInterval')?.disable();
      this.form.get('usageUploadCustomUnits')?.disable();
      this.form.get('usageUploadCustomInterval')?.disable();
    }
  }

  async handleUsageUploadIntervalChange(event: any) {

    if (event.value === 'custom') {
      this.form.get('usageUploadCustomUnits')?.enable();
      this.form.get('usageUploadCustomInterval')?.enable();
    } else {
      this.form.get('usageUploadCustomUnits')?.disable();
      this.form.get('usageUploadCustomInterval')?.disable();
    }
  }

  async handleShadowIntervalChange(event: any) {
  
    if (event.value === 'custom') {
      this.form.get('shadowUpdateCustomUnits')?.enable();
      this.form.get('shadowUpdateCustomInterval')?.enable();
    } else {
      this.form.get('shadowUpdateCustomUnits')?.disable();
      this.form.get('shadowUpdateCustomInterval')?.disable();
    }
  }


  async handleSatChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('dataEnabled')?.enable();
    } else {
      this.form.get('dataEnabled')?.disable();
    }
  }

  async handleShutdownChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('shutdownDays')?.enable();
      this.form.get('shutdownHrs')?.enable();
      this.form.get('shutdownMins')?.enable();
      this.form.get('shutdownSecs')?.enable();
    } else {
      this.form.get('shutdownDays')?.disable();
      this.form.get('shutdownHrs')?.disable();
      this.form.get('shutdownMins')?.disable();
      this.form.get('shutdownSecs')?.disable();
    }
  }

  async handleEmergencyReportChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('emergencyReportInterval')?.enable();
    } else {
      this.form.get('emergencyReportInterval')?.disable();

      // Need to disable if custom set
      this.form.get('reportingCustomUnits')?.disable();
      this.form.get('reportingCustomInterval')?.disable();
    }
  }

  async handleEmergencyReportIntervalChange(event: any) {
    console.log(`In handleEmergencyReportIntervalChange() - event. value = ${event.value}`);

    if (event.value === 'custom') {
      this.form.get('reportingCustomUnits')?.enable();
      this.form.get('reportingCustomInterval')?.enable();
    } else {
      this.form.get('reportingCustomUnits')?.disable();
      this.form.get('reportingCustomInterval')?.disable();
    }
  }

  async handleWifiChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('ssid')?.enable();
      this.form.get('mode')?.enable();
      this.form.get('country')?.enable();
      this.form.get('channel')?.enable();
      this.form.get('security')?.enable();
      this.form.get('wifiPassword')?.enable();
    } else {
      this.form.get('ssid')?.disable();
      this.form.get('mode')?.disable();
      this.form.get('country')?.disable();
      this.form.get('channel')?.disable();
      this.form.get('security')?.disable();
      this.form.get('wifiPassword')?.disable();
    }
  }

  async handleCellChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('apn')?.enable();
      this.form.get('cellularUsername')?.enable();
      this.form.get('cellularPassword')?.enable();
    } else {
      this.form.get('apn')?.disable();
      this.form.get('cellularUsername')?.disable();
      this.form.get('cellularPassword')?.disable();
    }
  }

  async handleDhcpChange(event: any) {
    const isChecked = event.checked;

    if (isChecked) {
      this.form.get('dhcpStart')?.enable();
      this.form.get('dhcpEnd')?.enable();
      this.form.get('leaseOption')?.enable();
    } else {
      this.form.get('dhcpStart')?.disable();
      this.form.get('dhcpEnd')?.disable();
      this.form.get('leaseOption')?.disable();
    }
  }

  async handleFirewallChange(event: any) {
    console.log("On handleFirewallChange() - event ", event);
    this.description = this.form.value.firewallProfile.description;
    console.log("description = ", this.form.value.firewallProfile.description);
  }

  async setupControls() {
    // Disable until Sat enabled is ON
    if (!this.form.value.satelliteEnabled) {
      this.form.get('dataEnabled')?.disable();
    }

    // Disable until Wifi enable is ON
    if (!this.form.value.wifiEnabled) {
      this.form.get('ssid')?.disable();
      this.form.get('mode')?.disable();
      this.form.get('country')?.disable();
      this.form.get('channel')?.disable();
      this.form.get('security')?.disable();
      this.form.get('wifiPassword')?.disable();
    }

    // Disable until Cellular enable is ON
    if (!this.form.value.cellularEnabled) {
      this.form.get('apn')?.disable();
      this.form.get('cellularUsername')?.disable();
      this.form.get('cellularPassword')?.disable();
    }

    // Disable until DHCP enable is ON
    if (!this.form.value.dhcpEnabled) {
      this.form.get('dhcpStart')?.disable();
      this.form.get('dhcpEnd')?.disable();
      this.form.get('leaseOption')?.disable();
    }

    // Disable Battery Pack
    if (!this.form.value.shutdownTimeEnabled) {
      this.form.get('shutdownDays')?.disable();
      this.form.get('shutdownHrs')?.disable();
      this.form.get('shutdownMins')?.disable();
      this.form.get('shutdownSecs')?.disable();
    }

    // Disable Report Interval
    if (!this.form.value.emergencyReportEnabled) {
      this.form.get('emergencyReportInterval')?.disable();
    }

    // Disable if Emergency Report Interval != 'custom'
    if (this.form.value.emergencyReportInterval !== 'custom') {
      this.form.get('reportingCustomUnits')?.disable();
      this.form.get('reportingCustomInterval')?.disable();
    }
   
    if (this.form.value.positionReportsEnabled) {
      this.form.get('positionReportsInterval')?.enable();
      this.form.get('positionReportsCustomUnits')?.enable();
      this.form.get('positionReportsCustomInterval')?.enable();
    } else {
      this.form.get('positionReportsInterval')?.disable();
      this.form.get('positionReportsCustomUnits')?.disable();
      this.form.get('positionReportsCustomInterval')?.disable();
    }
    
    if (this.form.value.remoteManagementEnabled) {
      this.form.get('remoteManagementInterval')?.enable();
      this.form.get('remoteManagementCustomUnits')?.enable();
      this.form.get('remoteManagementCustomInterval')?.enable();
    } else {
      this.form.get('remoteManagementInterval')?.disable();
      this.form.get('remoteManagementCustomUnits')?.disable();
      this.form.get('remoteManagementCustomInterval')?.disable();
    }

    if (this.form.value.statusReportsEnabled) {
      this.form.get('statusReportsInterval')?.enable();
      this.form.get('statusReportsCustomUnits')?.enable();
      this.form.get('statusReportCustomInterval')?.enable();
    } else {
      this.form.get('statusReportsInterval')?.disable();
      this.form.get('statusReportsCustomUnits')?.disable();
      this.form.get('statusReportsCustomInterval')?.disable();
    }

    if (this.form.value.callHistoryEnabled) {
      this.form.get('callHistoryInterval')?.enable();
      this.form.get('callHistoryCustomUnits')?.enable();
      this.form.get('callHistoryCustomInterval')?.enable();
    } else {
      this.form.get('callHistoryInterval')?.disable();
      this.form.get('callHistoryCustomUnits')?.disable();
      this.form.get('callHistoryCustomInterval')?.disable();
    }

    if (this.form.value.usageUploadEnabled) {
      this.form.get('usageUploadInterval')?.enable();
      this.form.get('usageUploadCustomUnits')?.enable();
      this.form.get('usageUploadCustomInterval')?.enable();
    } else {
      this.form.get('usageUploadInterval')?.disable();
      this.form.get('usageUploadCustomUnits')?.disable();
      this.form.get('usageUploadCustomInterval')?.disable();
    }
  }

  async prepopulateNetworkConfig() {
    // Network TAB
    this.form.patchValue({
      ipAddress: "192.168.111.1",
      networkMask: 24,
      primaryDns: "8.8.8.8",
      secondaryDns: "8.8.4.4",
      dhcpEnabled: true,
      dhcpStart: "192.168.111.50",
      dhcpEnd: "192.168.111.249",
      leaseOption: "24h"
    })
  }

  disableControls() {
    this.form.get('name')?.disable();
    this.form.get('maxDailyUsage')?.disable();
    this.form.get('dataRouting')?.disable();
    this.form.get('dataRoutingWired')?.disable();
    this.form.get('serialPort')?.disable();
    this.form.get('serialBaudRate')?.disable();
    this.form.get('flowControl')?.disable();
    this.form.get('devicePassword')?.disable();
    this.form.get('satelliteEnabled')?.disable();
    this.form.get('dataEnabled')?.disable();
    this.form.get('wifiEnabled')?.disable();
    this.form.get('ssid')?.disable();
    this.form.get('mode')?.disable();
    this.form.get('country')?.disable();
    this.form.get('channel')?.disable();
    this.form.get('security')?.disable();
    this.form.get('wifiPassword')?.disable();
    this.form.get('cellularEnabled')?.disable();
    this.form.get('apn')?.disable();
    this.form.get('cellularUsername')?.disable();
    this.form.get('cellularPassword')?.disable();
    this.form.get('ipAddress')?.disable();
    this.form.get('networkMask')?.disable();
    this.form.get('primaryDns')?.disable();
    this.form.get('secondaryDns')?.disable();
    this.form.get('dhcpEnabled')?.disable();
    this.form.get('dhcpStart')?.disable();
    this.form.get('dhcpEnd')?.disable();
    this.form.get('leaseOption')?.disable();
    this.form.get('firewallProfile')?.disable();

    this.form.get('shutdownTimeEnabled')?.disable();
    this.form.get('shutdownDays')?.disable();
    this.form.get('shutdownHrs')?.disable();
    this.form.get('shutdownMins')?.disable();
    this.form.get('shutdownSecs')?.disable();
    this.form.get('emergencyReportEnabled')?.disable();
    this.form.get('emergencyReportInterval')?.disable();
    this.form.get('reportingCustomUnits')?.disable();
    this.form.get('reportingCustomInterval')?.disable();

    this.form.get('positionReportsEnabled')?.disable();
    this.form.get('remoteManagementEnabled')?.disable();
    this.form.get('statusReportsEnabled')?.disable();
    this.form.get('callHistoryEnabled')?.disable();
    this.form.get('usageUploadEnabled')?.disable();
    
    this.form.get('positionReportsInterval')?.disable();
    this.form.get('remoteManagementInterval')?.disable();
    this.form.get('statusReportsInterval')?.disable();
    this.form.get('callHistoryInterval')?.disable();
    this.form.get('usageUploadInterval')?.disable();

    this.form.get('positionReportsCustomInterval')?.disable();
    this.form.get('callHistoryCustomInterval')?.disable();
    this.form.get('statusReportsCustomInterval')?.disable();
    this.form.get('remoteManagementCustomInterval')?.disable();
    this.form.get('usageUploadCustomInterval')?.disable();

    this.form.get('positionReportsCustomUnits')?.disable();
    this.form.get('callHistoryCustomUnits')?.disable();
    this.form.get('statusReportsCustomUnits')?.disable();
    this.form.get('remoteManagementCustomUnits')?.disable();
    this.form.get('usageUploadCustomUnits')?.disable();

    this.form.get('shadowUpdateInterval')?.disable();
    this.form.get('shadowUpdateCustomUnits')?.disable();
    this.form.get('shadowUpdateCustomInterval')?.disable();

    this.form.get('snmpEnabled')?.disable();

    this.form.get('videosoftEnabled')?.disable();
  }

  getChannelOptions (mode: boolean) {
    let channel = this.channelOptions5Ghz;
    // Default 2.4Ghz else 5Ghz
    if (mode) {
      channel = this.channelOptions5Ghz;
      this.mode5Ghz = true;
    } else {
      channel = this.channelOptions2_4Ghz;
      this.mode5Ghz = false;
    }
    return channel;
  }

  async onClose() {
    this.updateShadowModalOpen = false;
    this.deleteConfigModalOpen = false;
    this.addAdvancedRuleModalOpen = false;
    this.profileDescriptionsModalOpen = false;
    this.router.navigate(["/configs"]);
  }

  async displayHistoryDlg() {
    const rspConfig = await this.api.get< { "configs": ConfigModel[] } >(`/configs/group/${this.config_groupId}`);
    this.deviceConfigs = rspConfig?.configs;

    // Display History Modal
    this.configHistoryModalOpen = true;
  }

  async setupControlsForView() {
    this.disableControls();
  }

  getShutdownTime() {
    let shutdownTime= -1;
    if (this.form.value.shutdownTimeEnabled) {
      shutdownTime = this.convertShutdownTimesToSeconds();
    }
    return shutdownTime;
  }

  convertShutdownTimesToSeconds() {
    return (this.form.value.shutdownDays ?? 0) * 86400
      + (this.form.value.shutdownHrs ?? 0) * 3600
      + (this.form.value.shutdownMins ?? 0) * 60
      + (this.form.value.shutdownSecs ?? 0);
  }

  parseSosReportInterval(sosReportInterval: any) {
    // const remoteMgmt = configRsp.config.shadows.remoteManagement;
    let emergencyReportInterval: string = '';
    if (sosReportInterval) {
      emergencyReportInterval = Utils.altFind(this.emergencyReportIntervalOptions, function(x : any) {
        return x.value === sosReportInterval;
      })?.value || `custom`;
    }
    return emergencyReportInterval;
  }

  getSosReportInterval() {
    let sosReportInterval: string = '';
    if (this.form.value.emergencyReportEnabled) {
      if (this.form.value.emergencyReportInterval === 'custom') {
        sosReportInterval = this.form.value.reportingCustomInterval +
                            this.form.value.reportingCustomUnits;
      } else {
        sosReportInterval = this.form.value.emergencyReportInterval;
      }
    }
    return sosReportInterval;
  }

  getTimerDays(shutdownTime: number) {
    return (shutdownTime > 0 ? Math.floor(shutdownTime / 86400) : 0);
  }

  getTimerHours(shutdownTime: number) {
    return (shutdownTime > 0 ? Math.floor((shutdownTime % 86400) / 3600) : 0);
  }

  getTimerMins(shutdownTime: number) {
    return (shutdownTime > 0 ? Math.floor((shutdownTime % 3600) / 60) : 0);
  }

  getTimerSecs(shutdownTime: number) {
    return (shutdownTime > 0 ? shutdownTime % 60  : 0);
  }

  splitShutdownTime(shutdownTime: number) {
    const shutdownTimeDays = Math.floor(shutdownTime / 86400);
    const shutdownTimeHours = Math.floor((shutdownTime % 86400) / 3600);
    const shutdownTimeMinutes = Math.floor((shutdownTime % 3600) / 60);
    const shutdownTimeSeconds = shutdownTime % 60;

    let res = "";
    res += shutdownTimeDays + " Days ";
    res += shutdownTimeHours + " Hrs ";
    res += shutdownTimeMinutes + " Mins ";
    res += shutdownTimeSeconds + " Secs ";

    return res.trim();
  }

  getShutdownHrsMax() {
    let max: number = 24;
    if (this.form.value.shutdownDays === 30) {
      // Clear other values
      this.form.get('shutdownHrs')?.setValue(0);
      this.form.get('shutdownMins')?.setValue(0);
      this.form.get('shutdownSecs')?.setValue(0);
      max = 0;
    }
    return max;
  }

  getShutdownMinsMax() {
    let max: number = 60;

    if (this.form.value.shutdownDays === 30) {
      max = 0;
    } else if (this.form.value.shutdownDays === 29 &&
      this.form.value.shutdownHrs === 24) {
      max = 0;
    }
    return max;
  }

  getShutdownSecsMax() {
    let max: number = 60;
    if (this.form.value.shutdownDays === 30) {
      max = 0;
    } else if (this.form.value.shutdownDays === 29 &&
        this.form.value.shutdownHrs === 24) {
      max = 0;
    } else if (this.form.value.shutdownDays === 29 &&
        this.form.value.shutdownHrs === 23 &&
        this.form.value.shutdownMins == 60) {
      max = 0;
    }

    return max;
  }

  handleDataRoutingWiredChange(event: any) {
    console.log(`handleDataRoutingWiredChange() - checked = ${event.checked}`)
    console.log(`handleDataRoutingWiredChange() - dataRouting = ${this.form.value.dataRouting}`)
    const isChecked = event.checked;

    if (isChecked) {
      this.addWiredMessage()
      this.selectedDataRoutingOptions = this.dataRoutingOptionsWired
    } else {
      if (this.form.value.dataRouting !== "cell-sat") {
        this.form.get("dataRouting")?.setValue("none")
      }
      this.clear()
      this.selectedDataRoutingOptions = this.dataRoutingOptions
    }
    console.log(`Exiting handleDataRoutingWiredChange() - dataRouting = ${this.form.value.dataRouting}`)
  }

  addWiredMessage() {
    this.wiredMessages = [
      {severity:'warn', summary:'', detail:'Wired Internet connections ' +
       'require a external USB ethernet device, along with separate Internet ' +
       'service provided via ethernet over an external network. ' +
       'If, for example, you select Wired Internet Only and do not have this ' +
       'setup, you will not be able to connect to the Internet via the SkyLink.'}
    ];
  }

  clear() {
      this.wiredMessages = [];
  }

 }
