import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ApiKeyModel } from 'src/app/models/api-key.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { Utils } from 'src/app/util/utils';

@Component({
  selector: 'app-api-keys-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class ApiKeysCreateComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  @Input() visible = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  users: UserModel[] = [];
  createdKey?: ApiKeyModel;
  form = this.fb.group({
    purpose: [],
    user: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    try {
      this.blockUI?.start();
      const response = await this.api.get<{ "users": UserModel[] }>('/users');
      this.users = response.users.sort((a, b) => a.name.localeCompare(b.name));;
    } finally {
      this.blockUI?.stop();
    }
  }

  async submit() {
    if (this.form.value.user) {
      try {
        this.blockUI?.start();

        const userId = this.form.value.user;
        const user: UserModel = Utils.altFind(this.users, function(x: any) {return  x.id === userId})
        const orgId = user.organization.id;

        this.createdKey = await this.api.post<ApiKeyModel>(`/apiKeys`, {
          userId: userId,
          purpose: this.form.value.purpose,
          orgId: orgId
        });
      } catch (err) {
        this.messageService.addAll(parseApiErrorsToToast(err));
      } finally {
        this.blockUI?.stop();
      }
    }
  }

  /**
   * Lots of work in order to properly emit the closing events..
   * This updates the 2-way bound visible property and also fires the onClose event
   * @param evt
   */
  close(evt: Event) {
    this.createdKey = undefined;
    this.form.reset();
    this.visibleChange.emit(evt);
    this.onClose.emit(evt);
  }
}
