import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomerModel } from 'src/app/models/customer.model';
import { ResellerModel } from 'src/app/models/reseller.model';
import { UserModel, Roles } from 'src/app/models/user.model';
import { ApiService, parseApiErrorsToToast } from 'src/app/services/api.service';
import { OrgModel } from 'src/app/models/org.model';
import { Location } from '@angular/common';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { Utils } from 'src/app/util/utils';

@Component({
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class UsersEditComponent implements OnInit {
  resellers: ResellerModel[] = [];
  customers: CustomerModel[] = [];
  isEditing: boolean = false;
  user_id?: string;
  user?: any;
  allOrgs: OrgModel[] = [];
  orgs: OrgModel[] = [];
  currentUsername?: string;
  currentRole: string = 'Reviewer';
  currentRoleId: number = 4;  // Default to Reviewer
  currentOrgId?: string;

  isAdmin: boolean = false;
  isOps: boolean = false;
  isAccountManager: boolean = false;
  isReviewer: boolean = false;

  isVam: boolean = false;
  isReseller: boolean = false;
  isCustomer: boolean = false;

  deleteUserModalOpen = false;
  users: UserModel[] = [];

  form = this.fb.group({
    name: [],
    username: [],
    email: [, Validators.email],
    phone: [],
    address: [],
    isEnabled: [true],
    role: ['', Validators.required],
    organization: this.fb.group({
      id: []
    }),
  });

  roles:any [] = [];

  @BlockUI()
  blockUI: NgBlockUI | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private breadcrumbService: AppBreadcrumbService
  ) {
    const userStr = localStorage.getItem('currentUser');
    if (userStr) {
      const currentUser: UserModel = JSON.parse(userStr);
      console.log("currentUser = ", currentUser);
      this.currentUsername = currentUser?.username;

      this.isAdmin = currentUser?.role === 'Admin';
      this.isOps = currentUser?.role === 'Ops';
      this.isAccountManager = currentUser?.role === 'AccountManager';
      this.isReviewer = currentUser?.role === 'Reviewer';

      this.currentOrgId = currentUser?.organization.id;
      this.isVam = currentUser?.organization.type === 'VAM';
      this.isReseller = currentUser?.organization.type === 'Reseller';
      this.isCustomer = currentUser?.organization.type === 'Customer';

      // Convert User role to Account Manager
      const currentRole = currentUser?.role === 'User' ? 'AccountManager' : currentUser?.role;

      // Save Role id
      this.currentRoleId = Utils.altFind(Roles, function(x : any) {
        return x.value === currentRole;
      })?.id;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.isEditing = false;
      if (params.user_id) {
        if (!this.isReviewer) {
          this.isEditing = true;
        }
        this.user_id = params.user_id;
      }

      if (this.isReviewer) {
        this.breadcrumbService.setItems([{ label: 'Users', routerLink: '/users' }, { label: 'View' }])
      } else if (this.isEditing) {
        this.breadcrumbService.setItems([{ label: 'Users', routerLink: '/users' }, { label: 'Edit' }])
      } else {
        this.breadcrumbService.setItems([{ label: 'Users', routerLink: '/users' }, { label: 'Add' }])
      }
      this.load(this.user_id);
    });
  }

  async load(id?: string) {
    try {
      this.blockUI?.start();

      // Get all orgs
      const rsp = await this.api.get<{"orgs": OrgModel[]}>('/orgs');
      this.orgs = rsp.orgs.sort((a, b) => a.name.localeCompare(b.name));;
      this.allOrgs = this.orgs;
      console.log("allOrgs = ", this.allOrgs);

      if (this.isReviewer) {
        this.setupControlsForReviewer();
      } else {
        this.setupControlsForEdit();
      }

      // Load roles based on User logged on
      this.loadAllowedRoles();
      
      if (id) {
        this.user = await this.api.get(`/users/${id}`);
        console.log("user = ", this.user);

        if (this.user) {

          // If this is a ParentRole -> ONLY allow to View
          // Overwrite: isEditing, breadcrumbs and disable all controls
          // Treat as isReviewer
          if (this.isParentRole(this.user?.organization.id, this.user?.role)) {
            this.isEditing = false;
            this.isReviewer = true;
            this.breadcrumbService.setItems([{ label: 'Users', routerLink: '/users' }, { label: 'View' }])
            this.setupControlsForReviewer();
          }

          // Load Orgs for this user
          this.loadAllowedOrgs(this.user.role);

          // Load form
          this.form.patchValue(this.user);

          this.users.push(this.user);
          console.log(`loading this user= ${this.users[0].name}`);
        }
      }

    } finally {
      this.blockUI?.stop();
    }
  }

  async onSubmit() {
    try {
      this.blockUI?.start();

      let user = {}
      if (this.isEditing) {
        // Username and email cannot be updated during EDIT
        user = {
          id: this.user_id,
          organizationId: this.form.value.organization.id ? this.form.value.organization.id : null,
          name: this.form.value.name,
          phone: this.form.value.phone,
          address: this.form.value.address,
          isEnabled: this.form.value.isEnabled,
          role: this.form.value.role,
        }
      } else {
        user = {
          id: this.user_id,
          organizationId: this.form.value.organization.id ? this.form.value.organization.id : null,
          username: this.form.value.username,
          name: this.form.value.name,
          email: this.form.value.email,
          phone: this.form.value.phone,
          address: this.form.value.address,
          isEnabled: this.form.value.isEnabled,
          role: this.form.value.role,
        }
      };

      console.log("onSubmit() - user = ", user);

      if (this.isEditing) {
        await this.api.put(`/users/${this.user_id}`, user);
      } else {
        await this.api.post('/users', user);
      }

      this.router.navigate(["/users"]);

    } catch (error) {
      this.messageService.addAll(parseApiErrorsToToast(error));
    } finally {
      this.blockUI?.stop();
    }
  }

  async resetPassword() {
    if (this.user_id) {
      try {
        // IMPORTANT: This doesn't work as expected
        // Removed the Reset Password button from the UI
        await this.api.post(`/users/password-reset/${this.user_id}`, {});
        this.messageService.add({
          severity:'info',
          summary:'Reset Password',
          detail:'The password has been reset!'
        });
      } catch (error) {
        this.messageService.addAll(parseApiErrorsToToast(error));
      }
    }
  }

  async onCancel(event: Event) {
    console.log("onCancel - this.location = ", this.location);
    this.location.back();
  }

  async onClose() {
    this.router.navigate(["/users"]);
  }

  setupControlsForReviewer() {
    this.form.get('name')?.disable();
    this.form.get('username')?.disable();
    this.form.get('email')?.disable();
    this.form.get('phone')?.disable();
    this.form.get('address')?.disable();
    this.form.get('isEnabled')?.disable();
    this.form.get('role')?.disable();
    this.form.get('organization')?.disable();
  }

  setupControlsForEdit() {
    if (this.isEditing) {
      this.form.get('username')?.disable();
      this.form.get('email')?.disable();
    }
  }

  handleRoleChange(event: any) {
    console.log("handleRoleChange() - event.value = ", event.value);

    // Clear any preselected organization
    this.form.controls["organization"].get('id')?.setValue(null);

    this.loadAllowedOrgs(event.value);
  }

  isParentRole(orgId: string, role: string) {
    let parentRole = false;
    const roleId = Utils.altFind(Roles, function(x : any) {
      return x.value === role;
    })?.id;
    console.log(`Comparing roleId = ${roleId} currentRoleId = ${this.currentRoleId}`);
    if (orgId === this.currentOrgId && roleId < this.currentRoleId) {
      parentRole = true;
    }
    return parentRole;
  }

  /**
   * Load Allowed Organizations
   * - For Reviewer - we load all because it will see all in org (incl parents)
   * - If OPS chosen, display only VAM orgs
   * - If role chosen has higher ranking, then remove the parent Org
   * 
   * @param role 
   */
  loadAllowedOrgs(role: string) {
    console.log("In loadAllowedOrgs -  role = ", role);

    if (this.isReviewer) {
      this.orgs = this.allOrgs;
    } else {

      // If OPS only display orgs of type == "VAM"
      if (role === "Ops") {
        this.orgs = this.allOrgs.filter(org => org.type === "VAM");
      } else {
        const roleId = Utils.altFind(Roles, function(x : any) {
          return x.value === role;
        })?.id;
        console.log(`Comparing roleId = ${roleId} currentRoleId = ${this.currentRoleId}`);
        if (roleId >= this.currentRoleId) {
          this.orgs = this.allOrgs;
        } else {
          this.orgs = this.allOrgs.filter(org => org.id !== this.currentOrgId);
        }
      }
    }
  }

  /**
   * Roles:
   * - Admin (VAM/Reseller/Customer)
   * - Ops (VAM)
   * - Account Manager | User (VAM/Reseller/Customer)
   * - Reviewers (VAM/Reseller/Customer)
   */
  loadAllowedRoles() {
    console.log("In loadAllowedRoles()");

    if (this.isReviewer) {
      this.roles = Roles;
    } else {
      // Remove Ops
      // - if not VAM
      // - if VAM and !Admin or !Ops
      const rolesWithOrWithoutOps = Roles.filter(
        role =>  (this.isVam && this.isAccountManager && role.value !== 'Ops') ||
                 (this.isVam && (this.isAdmin || this.isOps)) ||
                 (this.isReseller && role.value !== 'Ops') ||
                 (this.isCustomer && role.value !== 'Ops')

      )
      console.log("rolesWithOrWithoutOps = ", rolesWithOrWithoutOps);

      // No children orgs
      if (this.allOrgs?.length == 1) {
        this.roles = rolesWithOrWithoutOps.filter(
          role => role.id >= this.currentRoleId
        );
      } else if (this.allOrgs?.length > 1) {
        this.roles = rolesWithOrWithoutOps;
      }
      console.log("this.roles = ", this.roles);
    }
  }
}
